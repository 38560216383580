import { ThemeVariables } from '@alyle/ui';

export const styles = (theme: ThemeVariables) => ({
    actions: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    cropping: {
      maxWidth: '422px',
      height: '202px',
      margin: '0 auto'
    },
    icon: {
      marginAfter: '.25em'
    },
    range: {
      textAlign: 'center',
      maxWidth: '400px'
    },
    rangeInput: {
      height: '20px',
      maxWidth: '124px',
      margin: '0',
      padding: '0',
  
      // styles for slider
      // http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html
      // removes default webkit styles
      '-webkit-appearance': 'none',
  
      border: `0`,
  
      // required for proper track sizing in FF
      width: '100%',
      '&::-webkit-slider-runnable-track': {
          width: '124px',
          height: '3px',
          background: '#ddd',
          border: 'none',
          borderRadius: '3px'
      },
      '&::-webkit-slider-thumb': {
          '-webkit-appearance': 'none',
          border: 'none',
          borderRadius: '50%'
      },
      '&:focus': {
          outline: 'none'
      },
      '&:focus::-webkit-slider-runnable-track': {
          background: '#ddd'
      },
  
      '&::-moz-range-track': {
          width: '124px',
          height: '2px',
          background: '#ddd',
          border: 'none',
          borderRadius: '3px'
      },
      '&::-moz-range-thumb': {
          border: 'none',
          height: '20px',
          width: '20px',
          borderRadius: '50%'
      },
  
      // hide the outline behind the border
      '&:-moz-focusring': {
          outline: '0'
      },
  
      '&::-ms-track': {
          width: '150px',
          height: '2px',
  
          // remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead
          background: 'transparent',
  
          // leave room for the larger thumb to overflow with a transparent border
          borderColor: 'transparent',
          borderWidth: '6px 0',
  
          // remove default tick marks
          color: 'transparent'
      },
      '&::-ms-fill-lower': {
          background: '#3982a4',
          borderRadius: '10px'
      },
      '&::-ms-fill-': {
          background: '#3982a4',
          borderRadius: '10px',
      },
      '&::-ms-thumb': {
          border: 'none',
          height: '20px',
          width: '20px',
          borderRadius: '50%'
      },
      '&:focus::-ms-fill-lower': {
          background: '#3982a4'
      },
      '&:focus::-ms-fill-upper': {
          background: '#3982a4'
      }
    }
  });