<ul class="{{'tabs ' + tabsHeaderClass}}" [attr.data-au]="'tabs-list'" device>
  <li *ngFor="let tab of tabs; let i = index;" class="{{'tab tab-' + i}}" (click)="selectTab(tab)" [class.active]="tab.active" [attr.data-au]="'tab'">
    <!-- <i *ngIf="tab.icon" class="m-icon va-m ro tab-icon" [attr.data-au]="'tab-icon'">{{tab.icon}}</i>
    <span class="tab-text" [attr.data-au]="'tab-title'">{{tab.title}}</span> -->
    <ng-container *ngTemplateOutlet="tab.tabTitle"></ng-container>
  </li>
  <div *ngIf="showSelectedTabTitle" class="{{'selected-tab-title tablet for-tab-' + selectedTabIndex}}" [attr.data-au]="'selected-tab-title-tablet'">{{tabs.toArray()[selectedTabIndex]?.title}}</div>
</ul>
<hr *ngIf="showTabColorDivider" class="{{'section-divider for-tab-' + selectedTabIndex}}" [attr.data-au]="'tab-color-divider'">
<div *ngIf="showSelectedTabTitle" class="{{'selected-tab-title mobile for-tab-' + selectedTabIndex}}" [attr.data-au]="'selected-tab-title-mobile'">{{tabs.toArray()[selectedTabIndex]?.title}}</div>
<div class="w-100 h-100 container-back-background" [attr.data-au]="'selected-tab-content-back-bg'">
  <div class="{{tabsBodyClass}}">
    <ng-content></ng-content>
  </div>
</div>
