import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnDestroy {
  subject: Subject<boolean>;
  title: string;
  message: string;
  confirmButton: string;
  cancelButton: string;
  
  constructor(private bsModalRef: BsModalRef) { }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }

  hideConfirmationDialog() {
    this.bsModalRef.hide();
    this.subject.next(false);
    this.subject.complete();
  }

  ngOnDestroy() {
    if (!this.subject.isStopped) {
      this.subject.next(false);
      this.subject.complete();
    }
  }

}
