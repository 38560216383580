import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService, CachingService } from '@services';

@Injectable()
export class CacheInvalidatorInterceptor implements HttpInterceptor {
  private BASE_URL: string = environment.apiBaseURL;
  private urls: any;

  constructor(
    private auth: AuthService,
    private cache: CachingService
  ) {
    this.urls = [
        {
            CacheId: environment.Caches.UserInfo,
            Url: `${this.BASE_URL}/clients/savedefaultstarttimes`
        }
    ]
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let toMarkAsDirty = this.urls.find(x => req.url.startsWith(x.Url))

    if (toMarkAsDirty) {
      this.cache.clearCacheItem(toMarkAsDirty.CacheId);
    }

    return next.handle(req);
  }

}
