<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png">
    </div>

    <form #lf [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate class="sign-form">
      <h4 class="form-head">Sign in to your account</h4>
      <div class="form-group has-float-label">
        <input autocomplete="username" autofocus="true" class="form-control" id="input-email" type="email"
          formControlName="opogousr" placeholder="Email" required [ngClass]="{'sign-error-input': loginError}">
        <label for="input-email" [ngClass]="{'sign-error-label': loginError}">Email</label>
      </div>
      <div class="form-group has-float-label" pVisibility>
        <input autocomplete="password" class="form-control" id="input-password" type="password"
          formControlName="opogopsw" placeholder="Password" required [ngClass]="{'sign-error-input': loginError}">
        <label for="input-password" [ngClass]="{'sign-error-label': loginError}">Password</label>
      </div>

      <a class="forgot-pass" [routerLink]="['/forgot-password']"(click)="logEventForForgottenPassword()">Forgotten my password</a>

      <div class="form-footer">
        <div *ngIf="loginError">
          <div *ngIf="loginError === LoginErrorType.INVALID_CREDENTIALS" class="text-center error-msg">
            <i class="m-icon va-m">info</i>
            Your username or password is incorrect, please try again, or if you have forgotten your password,
              <a [routerLink]="['/forgot-password']"(click)="logEventForForgottenPassword()" [attr.data-au]="'reset-password'">reset your password</a>
          </div>
          <div *ngIf="loginError === LoginErrorType.INVALID_GRANT" class="text-center error-msg">
            <i class="m-icon va-m">info</i>
            Sorry, we’re unable to log you in. Please contact your school administrator to ensure your account is active
          </div>
          <div *ngIf="loginError === LoginErrorType.SERVER_ERROR" class="text-center error-msg">
            <i class="m-icon va-m">info</i>
            We’re currently unable to log you in. If this continues to happen, please contact opogo on <a href="mailto:{{opogoService.servicesEmail}}">{{opogoService.servicesEmail}}</a>
          </div>
        </div>
        <div class="login-button-container">
          <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary"
          [style.background]="loading ? '#0C86B3' : '#20C4FF'"
          [style.borderColor]="loading ? '#0C86B3' : '#20C4FF'"
                (click)="logEventForSignIn()">Sign in</button>

        <span class="loader blue pr-4" [ngClass]="{ 'd-none' : !loading}"></span>
        </div>
      </div>

      <div class="keep-me-logged-in text-left">
        <div class="custom-control custom-checkbox small-checkbox">
          <input class="custom-control-input" formControlName="keepLoggedIn" id="keepLoggedInCheckbox" type="checkbox" checked>
          <label class="custom-control-label" for="keepLoggedInCheckbox">Keep me signed in</label>
          <label class="terms-of-service">By signing into the Opogo schools app you are agreeing to our <a [href]="TermsOfServiceUrl">terms of service.</a></label>
        </div>
      </div>
    </form>

    <div class="outside-footer">
      <div class="footer-label">
        Create new school account
        <a class="footer-link" href="{{opogoService.schoolSignUpUrl}}"
           (click)="logEventForSignUp()">sign up</a>
      </div>
    </div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>

</div>
