import { Injectable } from '@angular/core';
import { ClientSetting, UserSetting as UserSetting } from '@app/interfaces';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

const ClientAppId: number = 5;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpService) { }

  getUserSettings(): Observable<UserSetting[]> {
    let url: string = `/usersettings?appId=${ClientAppId}`;
    return this.http.get(url);
  }

  saveUserSetting(userSetting: UserSetting): Observable<any> {
    let url: string = `/usersettings`;
    return this.http.post(url, userSetting);   
  }

  getMarketingOption(): Observable<boolean> {
    let url: string = `/candidate/marketingoption`;
    return this.http.get(url);
  }

  setMarketingOption(on: boolean): Observable<any> {
    let url: string = `/candidate/marketingoption`;
    return this.http.post(url, on);
  }

  getClientSettings(): Observable<ClientSetting[]> {
    let url: string = `/clientsettings`;
    return this.http.get(url);
  }

  saveClientSetting(clientSetting: ClientSetting): Observable<any> {
    let url: string = `/clientsettings`;
    return this.http.post(url, clientSetting);   
  }

  saveClientDefaultStartTimes(startTimes: { Morning, Afternoon }): Observable<boolean> {
    let url: string = '/clients/savedefaultstarttimes';
    return this.http.post(url, startTimes);
  }

}
