import { Component, OnInit } from '@angular/core';
import { DeviceService } from './../../../services';

@Component({
  selector: 'app-password-reset-successful',
  templateUrl: './password-reset-successful.component.html',
  styleUrls: ['./password-reset-successful.component.scss']
})
export class PasswordResetSuccessfulComponent implements OnInit {

  constructor(
    public device: DeviceService,
  ) { }

  ngOnInit() {
  }

}
