import { Injectable } from "@angular/core";

export enum LocalUrl {
    BuildAJobNullState = 'BuildAJobNullState',
    TalentPoolNullState = 'TalentPoolNullState',
    TalentPoolNullStateMobile = 'TalentPoolNullStateMobile',
    BankStaffNullState = 'BankStaffNullState',
    BankStaffNullStateMobile = 'BankStaffNullStateMobile',
}

export type LocalUrls = {
    [key in LocalUrl]: string;
}

const localizedAssets = <LocalUrls>{
    BuildAJobNullState: $localize`assets/img/NoCandidates.png`,
    TalentPoolNullState: $localize`assets/img/TalentNull.png`,
    TalentPoolNullStateMobile: $localize`assets/img/TalentNullMobile.png`,
    BankStaffNullState: $localize`assets/img/BankStaffNull.png`,
    BankStaffNullStateMobile: $localize`assets/img/BankStaffNullMobile.png`,
}

Injectable()
export class LocalizedUrlProvider {
    getLocalizedUrl(key: LocalUrl) {
        return localizedAssets[key]
    }
}