import { Component, Input, OnInit } from '@angular/core';
import { DialogResult } from '@app/components/modals/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-talent-removal-dialog',
  templateUrl: './talent-removal-dialog.component.html',
  styleUrls: ['./talent-removal-dialog.component.scss']
})
export class TalentRemovalDialogComponent implements OnInit {
  public onClose: Subject<DialogResult>;

  candidate: any = null;

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    console.log(this.candidate);
  }

  onCancel() {
    this.bsModalRef.hide();
    this.onClose.next(DialogResult.Cancel);
  }

  onConfirm() {
    this.bsModalRef.hide();
    this.onClose.next(DialogResult.Confirm);
  }

}
