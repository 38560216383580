import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'format'
})
export class DayJsFormatPipe implements PipeTransform {

  transform(value: Date | string | dayjs.Dayjs, format: string = 'D'): any {
    const date = dayjs.isDayjs(value) ? value : dayjs(value);
    return date.format(format);
  }

}
