import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ClientService, AuthService, CachingService } from '../services';

@Injectable()
export class UserInfoResolver  implements OnDestroy {
  userInfoCacheId: number;
  logOutEventSubscription: any;

  constructor(
    private auth: AuthService,
    private cache: CachingService,
    private clientService: ClientService,
    private router: Router,
  ) {
    this.userInfoCacheId = environment.Caches.UserInfo;
    this.logOutEventSubscription = this.auth.LogOutEvent.subscribe(() => {
      this.cache.clearCacheItem(this.userInfoCacheId);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let cachedUserInfo = this.cache.getCacheItem(this.userInfoCacheId);

    if (!cachedUserInfo.Cache) {
      
      return this.clientService.getClientSummary().pipe(
        map(result => {
          this.cache.cacheData(this.userInfoCacheId, result);
          return result;
        }),
        catchError(error => {
          if(error.error.Message == "Authorization has been denied for this request."){
            this.router.navigate(['/login']);
          }
          return of(null);
        })
      );
    }

    return of(cachedUserInfo.Cache);
  }

  ngOnDestroy() {
    this.logOutEventSubscription.unsubscribe();
  }
}
