import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@services';

// 1 / 7 false (orange/red)/green
// 2 / 6 false
// 2 / 6 true


// 8 - unavailable
// (3 true) orange


@Pipe({
  name: 'authorization'
})
export class AuthorizationPipe implements PipeTransform {

  constructor(private auth: AuthService) {

  }

  transform(modifier: boolean): any {
    return modifier ? this.checkAuthorization() : !this.checkAuthorization();
  }

  checkAuthorization() {
    return this.auth.isLoggedIn() && location.pathname.indexOf('/reference') === -1 && location.pathname.indexOf('/set-password') === -1;
  }
}
