import { Component, OnInit } from '@angular/core';
import { AuthService, KeepLoggedKey } from './../../../services/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTitle } from './../../../interfaces/header';
import { DeviceService, HeaderService, OpogoService } from '@app/services';
import { environment } from "../../../../environments/environment";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { toBoolean } from '@alyle/ui';

const LoginErrorType = {
  INVALID_CREDENTIALS: 'invalid_credentials',
  INVALID_GRANT: 'invalid_grant',
  SERVER_ERROR: 'invalid_clientId'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginError: string;
  loginForm: FormGroup;
  loading = false;
  isProdEnvironment = environment.production;
  analytics = getAnalytics();

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private headerService: HeaderService,
    public opogoService: OpogoService,
    public device: DeviceService,

  ) {
    let keepLoggedIn = localStorage.getItem(KeepLoggedKey) ?? sessionStorage.getItem(KeepLoggedKey);
    this.loginForm = this.fb.group({
      opogousr: ['', Validators.compose([Validators.required, Validators.email])],
      opogopsw: ['', Validators.compose([Validators.required])],
      keepLoggedIn: [(keepLoggedIn === null || keepLoggedIn ===  undefined) ? true : toBoolean(localStorage.getItem(KeepLoggedKey))]
    });
  }

  get LoginErrorType() {
    return LoginErrorType;
  }

  get TermsOfServiceUrl() {
    return environment.termsOfServiceUrl;;
  }

  ngOnInit(): void {
    this.headerService.changeHeader(<AppTitle>{
      title: '',
      subtitle: ''
    });
  }

  onLogin(): void {
    this.auth.keepLoggedIn = this.loginForm.controls['keepLoggedIn'].value;
    this.loading = true;

    let userName = this.loginForm.get('opogousr').value;
    let password = this.loginForm.get('opogopsw').value;

    this.auth.login(userName, password).then(() => {
      const redirectedFromUrl = localStorage.getItem('redirectedFromUrl');

      redirectedFromUrl ? this.setNavigationUrl(redirectedFromUrl) : this.router.navigate(['/add-job']);
    }).catch((error) => {
      console.log('error login');

      this.loading = false;
      this.loginError = error.error.error; // yeah I know...
    });
  }

  logEventForSignUp() {
    logEvent(this.analytics, 'click_sign-up');
  }

  logEventForSignIn() {
    logEvent(this.analytics, 'click_sign-in');
  }

  logEventForForgottenPassword() {
    logEvent(this.analytics, 'click_forgotten-my-password');
  }

  setNavigationUrl(redirectedFromUrl) {
    let pathnameParams = '';
    if (redirectedFromUrl.includes('?')) {
      pathnameParams = redirectedFromUrl.slice(0, redirectedFromUrl.indexOf('?'));
    } else {
      pathnameParams = redirectedFromUrl;
    }
    const urlParams = {};
    const questionMarkIndex = redirectedFromUrl.indexOf('?');

    if (questionMarkIndex) {
      const searchParams = redirectedFromUrl.slice(questionMarkIndex, redirectedFromUrl.length);
      const pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, ' ')); },
        query = searchParams.substring(1);
      let match;

      while (match = search.exec(query)) {
        urlParams[decode(match[1])] = decode(match[2]);
      }
    }
    Object.keys(urlParams).length === 0 && urlParams.constructor === Object ? this.router.navigate([pathnameParams]) :
      this.router.navigate([pathnameParams], { queryParams: urlParams });
    localStorage.removeItem('redirectedFromUrl');
  }
}
