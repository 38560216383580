import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable()
export class LearnService {

    private ENC_KEY:string = CryptoJS.enc.Utf8.parse("A5DF3BCF97D0BF7535C5B9F9CC236F6F");
    private IV:string = CryptoJS.enc.Utf8.parse("2E4959A47B6E73EA");

    getLearnUrl(token: string = "") {
        let url = environment.learnUrl;

        if (token != "") {

            let encryption = CryptoJS.AES.encrypt(token, this.ENC_KEY, {
                iv: this.IV,
                mode: CryptoJS.mode.CBC
            });

            url += "?token=" + encodeURIComponent(encryption.toString());
        }

        return url;
    }

    gotoLearn(replace: boolean = false, token: string = "") {

        var url = this.getLearnUrl(token);

        if (replace)
            window.top.location.replace(url);
        else
            window.top.location = url; // We always ensure we make the top window redirect, in case we have frames I guess. Didn't see anywhere this would be a problem...
    }
}