import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {Observable, Subject} from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { AvailabilityDay } from '@app/models/availability-day.model';
import { map } from 'rxjs/operators';
import { Candidate, TalentPoolCandidateDetailed } from '@app/models/candidate.model';
import { TalentPoolResult } from '@app/models/talent-pool.model';
import { CandidateSelection } from '@modules/review-booking/data';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import {TalentPoolCandidateJob} from '@interfaces';

export interface CandidateDetailsRequest {
	CandidateId: string;
	IncludeGeneralDetails?: boolean;
	IncludeTestimonials?: boolean;
	IncludeAvailability?: boolean;
	AvailabilityFrom?: Dayjs;
	AvailabilityTo?: Dayjs;
	IncludeJobs?: boolean;
	JobsCompletedAtMySchool: boolean;
	IncludeCourses?: boolean;
}

@Injectable()
export class TalentPoolService {
  selectedForJobList: CandidateSelection[];
  candidateId: string;
  candidateType: string;

  private candidateSelectionUpdateSubject = new Subject<CandidateSelection>();
  candidateSelectionUpdate$: Observable<CandidateSelection> = this.candidateSelectionUpdateSubject.asObservable();

  constructor(private http: HttpService) {
  }

  postTalentPoolCandidates(candidateType: any): Observable<TalentPoolResult> {
    let url: string = `/clienttalentpool/filteredcandidates`;

    return this.http.post(url, candidateType).pipe(
      map(talentGroup => {
        let candidates = [];

        talentGroup.Candidates.forEach(candidate => {
          candidates.push(new Candidate().deserialize(candidate));
        });

        talentGroup.Candidates = candidates;

        return talentGroup;
      })
    );
  }

  getCandidateDetails(
    candidateId: string,
    includeGeneralDetails: boolean,
    includeTestimonials: boolean,
    includeAvailability: boolean,
    availabilityFrom: any,
    availabilityTo: any,
    includeJobs: boolean,
    jobsCompletedAtMySchool: boolean,
    includeCourses: boolean
    ): Observable<TalentPoolCandidateDetailed> {
    let url: string = '/ClientTalentPool/CandidateDetails';

    let request = this.createRequest(candidateId, includeGeneralDetails, includeTestimonials, includeAvailability, availabilityFrom, availabilityTo, includeJobs, jobsCompletedAtMySchool, includeCourses)

    return this.http.post(url, request).pipe(
      map(candidate => {
        return new TalentPoolCandidateDetailed().deserialize(candidate);
      })
    );
  }

  getTestimonials(candidateId: string) {
    let url: string = '/ClientTalentPool/CandidateDetailsGetTestimonials';

    let request = this.createRequest(candidateId, false, true)

    return this.http.post(url, request);
  }

  getAvailability(candidateId: string, availabilityFrom: any, availabilityTo: any) {
    let url: string = `/ClientTalentPool/CandidateDetailsGetAvailability?candidateId=${candidateId}&availabilityFrom=${availabilityFrom}&availabilityTo=${availabilityTo}`;

    return this.http.get(url).pipe(
      map(availabilityDays => {
        let availabilities = [];

        availabilityDays.forEach(availabilityDay => {
          availabilities.push(new AvailabilityDay().deserialize(availabilityDay));
        });

        return availabilities;
      }));
  }
  getCandidateJobs(candidateId: string, jobsCompletedAtMySchool: boolean): Observable<TalentPoolCandidateJob[]> {
    const url: string = '/ClientTalentPool/CandidateDetailsGetJobs';
    let request = this.createRequest(candidateId, true, false, false, null,null, true, jobsCompletedAtMySchool);

    return this.http.post(url, request);
  }

  getInterests(memberId: string,  jobType: number) {
    let url: string = `/ClientTalentPool/interest/${memberId}/${jobType}`;

    return this.http.get(url);
  }

  updateCandidateSelection(progress: CandidateSelection) {
    this.candidateSelectionUpdateSubject.next(progress);
  }

  createRequest(candidateId: string,
    includeGeneralDetails: boolean = true,
    includeTestimonials: boolean = false,
    includeAvailability: boolean = false,
    availabilityFrom: Dayjs | string = null,
    availabilityTo: Dayjs | string = null,
    includeJobs: boolean = false,
    jobsCompletedAtMySchool: boolean = false,
    includeCourses: boolean = false): CandidateDetailsRequest {
      return <CandidateDetailsRequest> {
        CandidateId: candidateId,
        IncludeGeneralDetails: includeGeneralDetails,
        IncludeTestimonials: includeTestimonials,
        IncludeAvailability: includeAvailability,
        AvailabilityFrom: availabilityFrom,
        AvailabilityTo: availabilityTo,
        IncludeJobs: includeJobs,
        JobsCompletedAtMySchool: jobsCompletedAtMySchool,
        IncludeCourses: includeCourses
      };
    }

}
