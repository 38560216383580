<div class="reference mt-3 col-12">
  <div class="row reference__header">
      <div class="col-4"></div>
      <div class="col-auto px-1 text-left text-md-center"><b>Excellent</b></div>
      <div class="col-auto px-1 text-left text-md-center"><b>Good</b></div>
      <div class="col-auto px-1 text-left text-md-center"><b>Satisfactory</b></div>
      <div class="col-auto px-1 text-left text-md-center"><b>Poor</b></div>
      <div class="col-auto px-1 text-left text-md-center"><b class="d-lg-none">N/A</b><b class="d-none d-lg-block">Not applicable</b></div>
  </div>
  
  <!-- Relationship with pupils -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScorePupilRelationship == null }">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Relationship with pupils</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithPupils4" name="relationWithPupils" class="custom-control-input" [(ngModel)]="reference.ScorePupilRelationship" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithPupils4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithPupils3" name="relationWithPupils" class="custom-control-input" [(ngModel)]="reference.ScorePupilRelationship" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithPupils3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithPupils2" name="relationWithPupils" class="custom-control-input" [(ngModel)]="reference.ScorePupilRelationship" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithPupils2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithPupils1" name="relationWithPupils" class="custom-control-input" [(ngModel)]="reference.ScorePupilRelationship" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithPupils1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithPupils0" name="relationWithPupils" class="custom-control-input" [(ngModel)]="reference.ScorePupilRelationship" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithPupils0"></label>
          </div>
      </div>
  </div>
  
  <!-- Relationship with colleagues -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScoreColleagueRelationship == null}">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Relationship with colleagues</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithColleagues4" name="relationWithColleagues" class="custom-control-input" [(ngModel)]="reference.ScoreColleagueRelationship" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithColleagues4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithColleagues3" name="relationWithColleagues" class="custom-control-input" [(ngModel)]="reference.ScoreColleagueRelationship" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithColleagues3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithColleagues2" name="relationWithColleagues" class="custom-control-input" [(ngModel)]="reference.ScoreColleagueRelationship" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithColleagues2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithColleagues1" name="relationWithColleagues" class="custom-control-input" [(ngModel)]="reference.ScoreColleagueRelationship" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithColleagues1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="relationWithColleagues0" name="relationWithColleagues" class="custom-control-input" [(ngModel)]="reference.ScoreColleagueRelationship" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="relationWithColleagues0"></label>
          </div>
      </div>
  </div>
  
  <!-- Health and attendance -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScoreAttendance == null}">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Attendance & reliability</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="healthAndAttendance4" name="healthAndAttendance" class="custom-control-input" [(ngModel)]="reference.ScoreAttendance" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="healthAndAttendance4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="healthAndAttendance3" name="healthAndAttendance" class="custom-control-input" [(ngModel)]="reference.ScoreAttendance" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="healthAndAttendance3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="healthAndAttendance2" name="healthAndAttendance" class="custom-control-input" [(ngModel)]="reference.ScoreAttendance" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="healthAndAttendance2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="healthAndAttendance1" name="healthAndAttendance" class="custom-control-input" [(ngModel)]="reference.ScoreAttendance" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="healthAndAttendance1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="healthAndAttendance0" name="healthAndAttendance" class="custom-control-input" [(ngModel)]="reference.ScoreAttendance" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="healthAndAttendance0"></label>
          </div>
      </div>
  </div>
  
  <!-- Subject Knowledge -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScoreKnowledge == null}">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Subject knowledge</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="subjectKnowledge4" name="subjectKnowledge" class="custom-control-input" [(ngModel)]="reference.ScoreKnowledge" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="subjectKnowledge4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="subjectKnowledge3" name="subjectKnowledge" class="custom-control-input" [(ngModel)]="reference.ScoreKnowledge" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="subjectKnowledge3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="subjectKnowledge2" name="subjectKnowledge" class="custom-control-input" [(ngModel)]="reference.ScoreKnowledge" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="subjectKnowledge2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="subjectKnowledge1" name="subjectKnowledge" class="custom-control-input" [(ngModel)]="reference.ScoreKnowledge" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="subjectKnowledge1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="subjectKnowledge0" name="subjectKnowledge" class="custom-control-input" [(ngModel)]="reference.ScoreKnowledge" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="subjectKnowledge0"></label>
          </div>
      </div>
  </div>
  
  <!-- Planning and preparation -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScorePlanning == null}">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Planning and preparation</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="planningAndPreparation4" name="planningAndPreparation" class="custom-control-input" [(ngModel)]="reference.ScorePlanning" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="planningAndPreparation4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="planningAndPreparation3" name="planningAndPreparation" class="custom-control-input" [(ngModel)]="reference.ScorePlanning" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="planningAndPreparation3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="planningAndPreparation2" name="planningAndPreparation" class="custom-control-input" [(ngModel)]="reference.ScorePlanning" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="planningAndPreparation2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="planningAndPreparation1" name="planningAndPreparation" class="custom-control-input" [(ngModel)]="reference.ScorePlanning" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="planningAndPreparation1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="planningAndPreparation0" name="planningAndPreparation" class="custom-control-input" [(ngModel)]="reference.ScorePlanning" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="planningAndPreparation0"></label>
          </div>
      </div>
  </div>
  
  <!-- Communication skills -->
  <div class="row" [ngClass]="{'uncompleted': isNotValid && reference.ScoreComms == null}">
      <div class="col-4 align-self-center py-2 pl-2 pl-sm-3">Communication skills</div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="communicationSkills4" name="communicationSkills" class="custom-control-input" [(ngModel)]="reference.ScoreComms" [value]="4" [disabled]="disabled">
              <label class="custom-control-label" for="communicationSkills4"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="communicationSkills3" name="communicationSkills" class="custom-control-input" [(ngModel)]="reference.ScoreComms" [value]="3" [disabled]="disabled">
              <label class="custom-control-label" for="communicationSkills3"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="communicationSkills2" name="communicationSkills" class="custom-control-input" [(ngModel)]="reference.ScoreComms" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="communicationSkills2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="communicationSkills1" name="communicationSkills" class="custom-control-input" [(ngModel)]="reference.ScoreComms" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="communicationSkills1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="communicationSkills0" name="communicationSkills" class="custom-control-input" [(ngModel)]="reference.ScoreComms" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="communicationSkills0"></label>
          </div>
      </div>
  </div>
</div>


<div class="reference mt-5 col-12">
  <div class="row reference__header">
      <div class="col-7 col-sm-5 col-md-4 w-46"></div>
      <!-- <div class="d-none d-sm-block col-auto px-1 px-md-3"></div> -->
      <div class="col-auto px-1 px-md-3"><b>Yes</b></div>
      <div class="col-auto px-1 px-md-3"><b>No</b></div>
      <div class="col-auto px-1 px-md-3"><b class="d-md-none">N/A</b><b class="d-none d-md-block">Not applicable</b></div>
  </div>

  <!-- Would you re-employ this candidate? -->
  <div class="row py-2" [ngClass]="{'uncompleted': isNotValid && reference.ReEmploy == null}">
      <div class="col-7 col-sm-5 col-md-4 align-self-center py-2 w-46">Would you re-employ this candidate?</div>
      <!-- <div class="d-none d-sm-block col-auto px-1 px-md-3 align-self-center"></div> -->
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="reEmploy1" name="reEmploy" class="custom-control-input" [(ngModel)]="reference.ReEmploy" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="reEmploy1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="reEmploy2" name="reEmploy" class="custom-control-input" [(ngModel)]="reference.ReEmploy" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="reEmploy2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="reEmploy0" name="reEmploy" class="custom-control-input" [(ngModel)]="reference.ReEmploy" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="reEmploy0"></label>
          </div>
      </div>
  </div>

  <!-- Safeguarding issues to be noted? -->
  <div class="row py-2" [ngClass]="{'uncompleted': isNotValid && reference.Safeguard == null}">
      <div class="col-7 col-sm-5 col-md-4 align-self-center py-2 pr-0 w-46">Have there been any safeguarding issues pertaining to this candidate that should be noted?</div>
      <!-- <div class="d-none d-sm-block col-auto px-1 px-md-3 align-self-center"></div> -->
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="safeGuard1" name="safeGuard" class="custom-control-input" [(ngModel)]="reference.Safeguard" [value]="1" [disabled]="disabled">
              <label class="custom-control-label" for="safeGuard1"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="safeGuard2" name="safeGuard" class="custom-control-input" [(ngModel)]="reference.Safeguard" [value]="2" [disabled]="disabled">
              <label class="custom-control-label" for="safeGuard2"></label>
          </div>
      </div>
      <div class="col-auto px-1 px-md-3 align-self-center">
          <div class="custom-control custom-radio">
              <input type="radio" id="safeGuard0" name="safeGuard" class="custom-control-input" [(ngModel)]="reference.Safeguard" [value]="0" [disabled]="disabled">
              <label class="custom-control-label" for="safeGuard0"></label>
          </div>
      </div>
  </div>
</div>

<!-- state safeguarding issues -->
<div class="row mb-3 safeguarding-notes-wrapper mx-0" [ngClass]="{'d-flex': reference.Safeguard === 1, 'uncompleted': isNotValid && (reference.Safeguard === 1 && (reference.SafeguardNotes == null || reference.SafeguardNotes == ''))}" *ngIf="!disabled || !(reference.SafeguardNotes == null || reference.SafeguardNotes == '')">
  <div class="col-12 col-md-3 pb-2 notes__text">If yes, please state safeguarding issues:</div>
  <div class="col-12 col-md-9 py-2 pr-0 pl-0 pl-md-3"><textarea class="safeguarding-notes mb-0" [ngClass]="{'uneditable': disabled}" [placeholder]="disabled ? '' : 'Enter safeguarding issues here'" [(ngModel)]="reference.SafeguardNotes" autoresize [disabled]="disabled"></textarea></div>
</div>

<!-- Additional notes -->
<div class="row mx-0" *ngIf="!disabled || !(reference.AdditionalNotes == null || reference.AdditionalNotes == '')">
  <div class="col-12 col-md-3 pb-2 notes__text">
      <strong>Additional notes (optional):</strong><br>
      Include character reference if required:
  </div>
  <div class="col-12 col-md-9 pr-0 pl-0 pl-md-3"><textarea [ngClass]="{'uneditable': disabled}" [placeholder]="disabled ? '' : 'Enter additional notes here'" [(ngModel)]="reference.AdditionalNotes" autoresize [disabled]="disabled" [style.backgroundColor]="disabled ? '#30caf7 !important' : ''" ></textarea></div>
</div>

<!-- Confirmation -->
<div class="row confirm mx-0" *ngIf="!disabled">
    <div class="col-12">
        <h2 class="text-center">Confirm reference</h2>
        <p class="mb-4">I, <strong>{{ reference.FirstName }}</strong>, confirm that <strong>{{ reference.Candidate.FullName }}</strong> held the position of <strong>{{ reference.Role }}, {{ reference.AgeGroup }}</strong> between the dates of <strong>{{ reference.DateFrom | date : "dd MMMM yyyy" }}</strong> and <strong>{{ reference.DateTo | date : "dd MMMM yyyy" }}</strong>, and the information I have supplied above is accurate.</p>
        
        <div class="custom-control custom-checkbox mb-2">
            <input class="custom-control-input" id="accepted_01" type="checkbox" [(ngModel)]="reference.AgreeShareWithCandidate" [disabled]="disabled">
            <label class="custom-control-label" for="accepted_01">I am happy to share this reference with {{ reference.Candidate.FullName }} if they request.</label>
        </div>
            
        <div class="custom-control custom-checkbox">
            <input class="custom-control-input" id="accepted_02" type="checkbox" [(ngModel)]="reference.AgreeShareWithSchools" [disabled]="disabled">
            <label class="custom-control-label" for="accepted_02">I am happy to share this reference with other schools  /  institutes if requested.</label>
        </div>

        <p class="uncompleted text-center" [ngClass]="{'d-block': isNotValid }">Please answer the questions highlighted in red above</p>

        <p class="uncompleted text-center" [ngClass]="{'d-block': submissionError }">Something went wrong, please try again later.</p>

        <button class="btn btn-primary col-12 mb-3" (click)="updateReference()" name="ie11sux" value="Submit" >Confirm & submit <span [ngClass]="{'loader right': submitLoader}"></span></button>
        <p class="mb-0 text-center">Opogo trading under Thames Teachers</p>
    </div>
</div>

<div class="row confirm confirmation--statement mx-0" *ngIf="showConfirmationStatement()">
    <div class="col-12 text-center">
        <h2>Confirmation statement</h2>
        <p>This reference was provided on <strong> {{ reference.CreatedOn | date : "dd MMMM yyyy" }} </strong> by <strong>{{ reference.FirstName }} {{ reference.LastName }}</strong>, <strong>{{ reference.JobTitle }}</strong> at <strong>{{ reference.OrganisationName }}</strong> to confirm that <strong>{{ reference.Candidate.FullName }}</strong> held the position of <strong>{{ reference.Role }}, {{ reference.AgeGroup }}</strong> between the dates of <strong>{{ reference.DateFrom | date : "dd MMMM yyyy" }}</strong> and <strong>{{ reference.DateTo | date : "dd MMMM yyyy" }}</strong>.</p>
        <p class="mb-0 pb-0">Opogo trading under Thames Teachers</p>
    </div>
</div>