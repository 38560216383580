import { Component, OnInit } from '@angular/core';
import { DeviceService, HeaderService, LearnService, OpogoService } from '@app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sorry-page',
  templateUrl: './sorry-page.component.html',
  styleUrls: ['./sorry-page.component.scss']
})
export class SorryPageComponent implements OnInit {

  constructor(
    public device: DeviceService,
    public opogoService: OpogoService,
    private headerService: HeaderService,
    private router: Router,
    private learnService: LearnService
  ) { }

  ngOnInit() {
  }

  goToSignIn() {
    this.learnService.gotoLearn(true);
  }
}
