import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-expandable-list-item',
  templateUrl: './expandable-list-item.component.html',
  styleUrls: ['./expandable-list-item.component.scss']
})
export class ExpandableListItemComponent implements OnInit {
  @Input() showImage: boolean = true;
  @Input() showWarning: boolean = false;
  @Input() showSuccess: boolean = false;
  @Input() expandedSubtitle: boolean = false;
  @Input() expandedImage = false;
  @Input() expanded: boolean = false;
  @Input() expandable: boolean = true;
  @Input() highlighted: boolean = false;
  @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();  

  constructor() { }

  ngOnInit() {
  }

  toggleItem() {
    this.expanded = !this.expanded;
    this.onToggle.emit();
  }

}
