import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayNumber'
})
export class DisplayNumberPipe implements PipeTransform {
    
    /**
     * Returns string representation of a numeric value.
     * If value is of float type, the returned string will
     * be in double-precision format.
     * @param value 
     * @returns formatted value
     */
    transform(value: any): string {

        if (value && value.toString().split('.')[1] && value.toString().split('.')[1].length === 1) {
            return value.toFixed(2);
        }

        return value ? value.toString() : value;
    }
}