import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { AuthService, HeaderService } from './../../../services/index';
import { AppTitle } from './../../../interfaces/header';
import { default as Utils } from './../../../utils';
import { DeviceService } from './../../../services/device.service';
import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  analytics = getAnalytics();

  notFound = false;
  serverError: string;
  forgotPasswordForm: FormGroup<any>;
  tokenHasExpired = false;
  submitted = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private fb: FormBuilder,
    public device: DeviceService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      params = Utils.paramsKeyToLower(params);
      this.tokenHasExpired = params['tokenhasexpired'];
    });
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      opogousr: ['', Validators.compose([Validators.required, Validators.email])],
    });
    this.headerService.changeHeader(<AppTitle>{
      title: '',
      subtitle: ''
    });
  }

  logEventForGetNewPassword() {
    logEvent(this.analytics, 'click_get-new-password');
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.auth.resetPassword(this.forgotPasswordForm.get('opogousr').value).subscribe(
        result => {
          console.log('ResetPassword', result);
          this.router.navigate(['/forgot-password-email-confirmation']);
        },
        error => {
          if (error.statusText === 'Not Found') {
            this.notFound = true;
            this.serverError = null;
          } else {
            this.serverError = error.statusText;
          }
          this.forgotPasswordForm.get('opogousr').setErrors({ serverError: true });
        }
      );
    }
  }

}
