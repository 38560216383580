import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult } from '../modal';

@Component({
  selector: 'app-confirm-dialog-simple',
  templateUrl: './confirm-dialog-simple.component.html',
  styleUrls: ['./confirm-dialog-simple.component.scss']
})
export class SimpleConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SimpleConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      description: string,
      closeButtonText: string,
      confirmButtonText: string,
      styleClass: string
  }) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close(DialogResult.Cancel);
  }

  onConfirm() {
    this.dialogRef.close(DialogResult.Confirm);
  }

}
