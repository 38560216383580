<div class="container validate-email" *ngIf="showLoader">
  <div class="row justify-content-center">
    <div class="col-12 text-center pt-3 mb-4">
      <h2>Please wait... <span class="loader blue right"></span></h2>
    </div>
  </div>
</div>

<div class="email-border" *ngIf="emailVerified">
<!-- <div class="email-border"> -->

  <div class="container validate-email">
    <div class="row justify-content-center">
      <div class="col-12 logo my-5"></div>
      <div class="col-12 text-center pt-3 mb-4">
        <h2>Thank you. <br>Your Opogo account is now verified</h2>
      </div>
      <div class="col-12 like" *ngIf='show'></div>
      <button *ngIf='show' class="btn btn-primary col-sm-2 col-8 mx-2 mb-0 mt-4 mb-md-3 mt-md-5" (click)="signInCommunity()">Sign in to community</button>
      <button *ngIf='show' class="btn btn-primary col-sm-2 col-8 mx-2 mb-0 mt-4 mb-md-3 mt-md-5" (click)="signInApp()">Sign in to mobile app</button>
      
      <button *ngIf='mediaShow' class="btn btn-primary col-sm-2 col-8 mx-2 mb-0 mt-4 mb-md-3 mt-md-5" (click)="signInApp()">Sign in to Opogo</button>
    </div>
  </div>
</div>

<div class="container validate-email" *ngIf="!emailVerified && !showLoader">
  <div class="row justify-content-center">
    <div class="col-12 logo my-5"></div>
    <div class="col-12 text-center pt-3 mb-4">
      <h2>Sorry, we couldn't verify your email. <br>Please try again.</h2>
    </div>
  </div>
</div>