import { NgModule } from "@angular/core";
import { AvailabilityDayComponent } from "./availability-day/availability-day.component";
import { TimeSelectComponent } from "./time-select/time-select.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared.module";
import { CommonControlsModule } from "../modules/commoncontrols/commoncontrols.module";
import { LocalizationModule } from '@app/localization/localization.module';
import { BookingWidgetCircleComponent } from './booking-widget-circle/booking-widget-circle.component';
import { TooltipComponent } from './tooltip/tooltip.component'

@NgModule({
    declarations: [
        AvailabilityDayComponent,
        TimeSelectComponent,
        BookingWidgetCircleComponent,
        TooltipComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CommonControlsModule,
        LocalizationModule
    ],
    exports: [
        AvailabilityDayComponent,
        TimeSelectComponent,
        BookingWidgetCircleComponent,
        TooltipComponent
    ]
})
export class ControlsModule { }