import { InjectionToken } from "@angular/core";

export enum FEATURE_FLAG {
  DARTG = 'DARTG',
  CANDIDATE_SUMMARY = 'CANDIDATE_SUMMARY',
  VIEW_CV = 'VIEW_CV',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS', 
  TRAVEL_BUDDY = 'TRAVEL_BUDDY',
  VETTING_STATEMENT = 'VETTING_STATEMENT',
  STAFF_MANAGEMENT = 'STAFF_MANAGEMENT',
  INVOICES = 'INVOICES',
  TALENT_POOL_WIDGET = 'TALENT_POOL_WIDGET',
  TIMESHEET_WIDGET = 'TIMESHEET_WIDGET',
  TALENT_PROFILE_WIDGET = 'TALENT_PROFILE_WIDGET',
  MANAGE_JOBS_WIDGET = 'MANAGE_JOBS_WIDGET'
}

export interface FeatureFlagsService {
  getFeatureFlag(flagName: FEATURE_FLAG): Promise<boolean>;
}
  
export const FEATURE_FLAGS_SERVICE = new InjectionToken<FeatureFlagsService>('feature.flags.service');