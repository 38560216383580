import { Injectable } from '@angular/core';
import { Candidate } from '@app/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private widgetSubject = new Subject<boolean>();

  private candidateBookingDetailsSubject: Subject<Candidate[]> = new Subject<Candidate[]>;

  // Observable to subscribe to for  updates
  widget$: Observable<boolean> = this.widgetSubject.asObservable();
  candidateDetails$: Observable<Candidate[]> = this.candidateBookingDetailsSubject.asObservable();

  updateWidgetStatus(status: boolean) {
    this.widgetSubject.next(status);
  }

  updateCandidates(candidates: Candidate[]) {
    this.candidateBookingDetailsSubject.next(candidates);
  }
}
