import { Pipe, PipeTransform } from '@angular/core';
import { Label, LocalizedTextProvider, Symbol } from '../providers/localized-text.provider';

@Pipe({
  name: 'localizeText'
})
export class LocalizeTextPipe implements PipeTransform {

  constructor(private provider: LocalizedTextProvider) { }

  transform(label: Label, ...args: unknown[]): string {
    return this.provider.getLocalizedText(label);
  }

}
