import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { AvailabilityType } from './../models/availability-day.model';
import { AvailabilityDay } from './../models/availability-day.model';

@Pipe({
  name: 'availability'
})
export class AvailabilityPipe implements PipeTransform {

  transform(value: AvailabilityDay, currentDate: Date | string | dayjs.Dayjs, selected: boolean = false): any {
    const now = dayjs.isDayjs(currentDate) ? currentDate : dayjs(currentDate);
    const availability = dayjs.isDayjs(value.Date) ? value.Date : dayjs(value.Date);
    const styles = {};

    if (now.isSameOrBefore(availability, 'day')) {
      if (!value.Type && value.Type !== 0) {
        value.Type = value.AvailabilityType;
      }

      switch (value.Type) {
        case AvailabilityType.NotSet: {
          styles['not-set'] = true;
          break;
        }
        case AvailabilityType.AvailableAllDay: {
          styles['full'] = true;
          break;
        }
        case AvailabilityType.AvailableAM: {
          styles['am'] = true;
          break;
        }
        case AvailabilityType.AvailablePM: {
          styles['pm'] = true;
          break;
        }
        case AvailabilityType.BookedAllDay: {
          styles['all-day'] = true;
          break;
        }
        case AvailabilityType.BookedAM: {
          styles['booked-am'] = true;
          break;
        }
        case AvailabilityType.BookedPM: {
          styles['booked-pm'] = true;
          break;
        }
        case AvailabilityType.AvailableAM_BookedPM: {
          styles['am_booked-pm'] = true;
          break;
        }
        case AvailabilityType.BookedAM_AvailablePM: {
          styles['pm_booked-am'] = true;
          break;
        }
        case AvailabilityType.Unavailable: {
          styles['unavailable'] = true;
          break;
        }
        case AvailabilityType.BookedAllDayCurrentClient: {
          styles['all-day-current-client'] = true;
          break;
        }
        case AvailabilityType.BookedAMCurrentClient: {
          styles['booked-am-current-client'] = true;
          break;
        }
        case AvailabilityType.BookedPMCurrentClient: {
          styles['booked-pm-current-client'] = true;
          break;
        }
        case AvailabilityType.AvailableAM_BookedPMCurrentClient: {
          styles['am_booked-pm-current-client'] = true;
          break;
        }
        case AvailabilityType.BookedAMCurrentClient_AvailablePM: {
          styles['pm_booked-am-current-client'] = true;
          break;
        }
        case AvailabilityType.BookedAMCurrentClient_BookedPM: {
          styles['booked-am-current-client_booked-pm'] = true;
          break;
        }
        case AvailabilityType.BookedAM_BookedPMCurrentClient: {
          styles['booked-am_booked-pm-current-client'] = true;
          break;
        }
        default: {
          break;
        }
      }

      if (selected) {
        styles['selected'] = true;
      } else {
        styles['not-selected'] = true;
      }

    } else {
      styles['past'] = true;
    }

    return styles;
  }
}
