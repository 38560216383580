import { Tag } from "@app/models";

export interface Content {
  Id: number;
  Title: string;
  Type: ContentType;
  TypeTitle: string;
  MainImage: string;
  MainVideoURL: string;
  ShortDescription: string;
  Description: string;
  CreatedOn: Date;
  LastModified: Date;
  Visibility: ContentVisibility;
  ContentTopic: ContentTopic;
  PublishedDateTime: Date;
  AuthorName: string;
  AuthorRole: string;
  AuthorLogo: string;
  Tags: Tag[];
  PendingReviewStatus: ContentPendingReviewStatus;
  VisibleForPartners: any[];
}

export enum ContentType {
  Post = 0,
  Course = 1,
  Daily = 2,
  Event = 3
}

export enum ContentVisibility {
  Draft = 0,
  AllMembers = 1,
  MemberTypes = 2,
  PartnerOnly = 3,
  Public = 4,
  Archived = 5,
  MyNetwork = 6,
  SpecificPartners = 7
}

export interface ContentTopic {
  Id: number;
  Name: string;
  Description: string;
  ImageURL: string;
}

export enum ContentPendingReviewStatus {
  Default = 0,
  SentForReview = 1,
  ReviewAccepted = 2,
  ReviewRejected = 3
}
