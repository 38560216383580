import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

/** 
 * A simple route reuse strategy implementation
 * that allows us to cache routes between certain 
 * navigation sequences. As of now (3/11/2023), we 
 * only use this for the back button functionality
 * from booking confirmation (being able to go back 
 * and keep all results & selections)
 */
export class CustomReuseStrategy implements RouteReuseStrategy {

    rootComponents: string[] = [ "DashboardComponent" ];

    /** 
     * This object allows us to cache routes by path
     * with the path being route.routeConfig.path
     */
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    /** 
     * This object allows us to map certain route flows
     * where we wish to cache routes.
     */
    routesToCache: { from: string, to: string, componentMatch?: string }[] = [
        { from: "", to: "book", componentMatch: "HomeComponent" },
        { from: "", to: "book-all",  componentMatch: "HomeComponent" },
        { from: "", to: "book", componentMatch: "TalentHomeComponent" },
        { from: "profile", to: "book", componentMatch: "TalentProfileComponent" },
        { from: "list", to: "book", componentMatch: "TalentListComponent" }
    ];

    /** 
     * This allows for easier identification of route flows,
     * since the ActivatedRouteSnapshot params only give information
     * about the destination path. This is being overwritten by 
     * 'shouldReuseRoute' every time the current path differs from the next 
     */
    currentNavigationSequence: { from: string, to: string };

    /** 
     * Decides when the route should be stored
     * @param route This is, at least as I understand it, the route that the user is currently on, and we would like to know if we want to store it
     * @returns boolean indicating that we want to (true) or do not want to (false) store that route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        let _shouldDetach = this.routesToCache.some(r => r.from === this.currentNavigationSequence?.from && r.to === this.currentNavigationSequence?.to);
        let shouldClear = !this.routesToCache.some(r => r.from === this.currentNavigationSequence?.from || r.from === this.currentNavigationSequence?.to)

        if (shouldClear) {
            this.storedRouteHandles.clear();
        }
            
        return _shouldDetach;
    }
    
    /**
     * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
     * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
     * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
       if (handle) {
        this.storedRouteHandles.clear();
        
        this.storedRouteHandles.set(route.routeConfig.path, handle);
       }
    }
   
    /**
     * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
     * @param route The route the user requested
     * @returns boolean indicating whether or not to render the stored route
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        let routeToCache = this.routesToCache.find(r => 
            r.from === this.currentNavigationSequence.to && 
            r.to === this.currentNavigationSequence.from && 
            route.routeConfig.data && 
            route.routeConfig.data.componentKey === r.componentMatch
        );

        return routeToCache && this.storedRouteHandles.has(routeToCache.from);
    }
   
    /** 
     * Finds the locally stored instance of the requested route, if it exists, and 
     * the component type matches, returns it
     * @param route New route the user has requested
     * @returns DetachedRouteHandle object which can be used to render the component
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (!route.routeConfig) return null;
        if (route.routeConfig.loadChildren) return null;

        let routeToCache = this.routesToCache.find(r => r.from === route.routeConfig.path);
        let detachedRouteHandle = null;

        if (routeToCache) {
            if (this.storedRouteHandles.has(routeToCache.from) && (this.storedRouteHandles.get(routeToCache.from) as any).componentRef.componentType === route.component) {
                detachedRouteHandle = this.storedRouteHandles.get(routeToCache.from);
            }
        }

        return detachedRouteHandle;
    }
   
    /** 
     * Determines whether or not the current route should be reused
     * @param future The route the user is going to, as triggered by the router
     * @param curr The route the user is currently on
     * @returns boolean basically indicating true if the user intends to leave the current route
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        this.currentNavigationSequence = { from: curr.routeConfig?.path, to: future.routeConfig?.path };

        return curr.routeConfig === future.routeConfig;
    }
}