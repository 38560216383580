interface String {
    toHtml();
    toUrl();
    toEncode();
}

String.prototype.toHtml = function () {
    return String(this).replace(/\n/gi, '<br>');
}

String.prototype.toUrl = function () {
    return String(this).replace(/ /gi, '_').toLowerCase();
}

String.prototype.toEncode = function () {
    return String(this)
        .replace(/\+/gi, '%2B')
        .replace(/\//gi, '%2F')
        .replace(/\=/gi, '%3D');
}
