import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";

@Injectable()
export class MobileService {

    appStoreUrl: string = environment.appStoreUrl;
    playStoreUrl: string = environment.playStoreUrl;

    isiOS(): boolean {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent)
    }

    isAndroid(): boolean {
        return /Android/i.test(navigator.userAgent)
    }

    isOtherMobile(): boolean {
        return /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }

    goToMobileStore() {

        if (this.isiOS())
            window.top.location.href = this.appStoreUrl;
        else
            window.top.location.href = this.playStoreUrl;

    }
}