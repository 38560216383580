import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class HttpService {
  private BASE_URL: string = environment.apiBaseURL;

  constructor(private auth: AuthService, private http: HttpClient) {
  }

  get(url: string, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.BASE_URL}${url}`;
      return this.http.get(url, options); //.catch(this.handleError);
    }
    return of(null);
  }

  getNoLoggedIn(url: string, options?: any): Observable<any> {
    url = `${this.BASE_URL}${url}`;
    return this.http.get(url, options); //.catch(this.handleError);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.BASE_URL}${url}`;
      return this.http.post(url, body, options); //.catch(this.handleError);
    }
    return of(null);
  }

  postNoLoggedIn(url: string, body: any, options?: any): Observable<any> {
    url = `${this.BASE_URL}${url}`;
    return this.http.post(url, body, options); //.catch(this.handleError);
  }

  postToken(body: any, options?: any): Observable<any> {
    let baseUrl = `${this.BASE_URL}`.endsWith('/api') ? `${this.BASE_URL}`.slice(0, -4) : `${this.BASE_URL}`;
    let url: string = `${baseUrl}/token`;
    return this.http.post(url, body, options);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.BASE_URL}${url}`;
      return this.http.put(url, body, options); //.catch(this.handleError);
    }
    return of(null);
  }

  delete(url: string, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.BASE_URL}${url}`;
      return this.http.delete(url, options); //.catch(this.handleError);
    }
    return of(null);
  }

  request(method: string, url: string, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.BASE_URL}${url}`;
      return this.http.request(method, url, options); //.catch(this.handleError);
    }
    return of(null);
  }

  private handleError(error: Response | any) {
    console.error('Service::handleError', error);
    return observableThrowError(error);
  }
}
