import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Permission } from "@interfaces/user";
import { AuthService, ChatService, HeaderService, LearnService } from "@app/services";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  userSummary: any;
  hasJobBookingPermission = false;
  hasContentViewingPermission = false;
  hasJobCheckingInPermission = false;
  hasJobSigningOffPermission = false;
  hasInvoicePermission = false;
  hasClientAdministratorPermission = false;
  hasJobConfirmationPermission = false;
  hasTalentPoolUsePermission = false;
  joinCommunityLink = "";
  userToken: string;

  constructor(
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private chatService: ChatService,
    private learnService: LearnService
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.userSummary = data.user;
      this.userToken = this.auth.token;
      this.joinCommunityLink = this.learnService.getLearnUrl(this.auth.token);

      if (this.userSummary) {
        this.hasJobBookingPermission =
          this.userSummary.Permissions.indexOf(Permission.JobBooking) !== -1;
        this.hasContentViewingPermission =
          this.userSummary.Permissions.indexOf(Permission.ContentViewing) !== -1;
        this.hasJobCheckingInPermission =
          this.userSummary.Permissions.indexOf(Permission.JobCheckingIn) !== -1;
        this.hasJobSigningOffPermission =
          this.userSummary.Permissions.indexOf(Permission.JobSigningOff) !== -1;
        this.hasTalentPoolUsePermission =
          this.userSummary.Permissions.indexOf(Permission.TalentPool) !== -1;
        this.hasInvoicePermission = 
          this.userSummary.Permissions.indexOf(Permission.Invoice) !== -1;
        this.hasClientAdministratorPermission =
          this.userSummary.Permissions.indexOf(Permission.ClientAdministrator) !== -1;
        this.hasJobConfirmationPermission =
          this.userSummary.Permissions.indexOf(Permission.JobConfirmation) !== -1;
      }

      this.activatedRoute.queryParams.subscribe((params: Params) => {
        var chatTo = params['chatTo'];
        var chatRoom = this.chatService.tryGetChatRoomByContactUserId(chatTo);
        
        if (chatRoom !== undefined) {
          this.chatService.selectChatRoom(chatRoom);
        }
      });

      if (this.hasJobBookingPermission) {
        this.router.navigate(["/add-job"]);
        return;
      }
      if (this.hasJobSigningOffPermission) {
        this.router.navigate(["/manage-jobs/completedjobs"]);
        return;
      }
      if (this.hasContentViewingPermission) {
        if (!this.hasClientAdministratorPermission && !this.hasJobConfirmationPermission && !this.hasInvoicePermission) {
          this.learnService.gotoLearn();
        } else {
          this.router.navigate(["/hub"]);
        }
        return;
      }
    });
  }

  ngOnInit() { }
}
