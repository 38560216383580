import * as dayjs from 'dayjs';
import { RoleType, Pagination, Utils } from '../modules/_shared';
import { Dayjs } from 'dayjs';

export enum Setter {
  start = 1,
  end = 2
}

export interface DateFrame {
  start: Dayjs;
  end: Dayjs;
  range: Dayjs[];
}

export class CalendarPageState {
  roleType: RoleType;
  rolePagination: Pagination = new Pagination();
  //poolType: PoolType;
  poolPagination: Pagination = new Pagination();
  startDate: Dayjs;
  currentDay: Dayjs = dayjs();
  currentMonday: Dayjs = dayjs(Utils.weekStart(this.currentDay));
  weekdays: Dayjs[] = [];

  constructor(countDays: number, startDate: Dayjs = dayjs()) {
    this.startDate = startDate;
    this.fillWeek(countDays);
  }

  fillWeek(countDays: number) {
    this.weekdays = [];

    let day = dayjs(this.startDate);

    for (let i = 0; i < countDays; i++) {
      this.weekdays.push(day);
      day = day.add(1, 'days');
    }
  }

  nextWeek(countDays: number) {
    const weekStart = dayjs(Utils.nextWeekStart(this.weekdays[0]));

    this.weekdays = [];

    for (let i = 0; i < countDays; i++) {
      let weekDay = weekStart.add(i, 'days');

      this.weekdays.push(weekDay);
    }
  }

  previousWeek(countDays: number) {
    const weekStart = dayjs(Utils.previousWeekStart(this.weekdays[0]));

    this.weekdays = [];

    for (let i = 0; i < countDays; i++) {
      let weekDay = weekStart.add(i, 'days');

      this.weekdays.push(weekDay);
    }
  }

  get weekStart() {
    if (!this.weekdays.length) {
      return null;
    }

    return this.weekdays[0];
  }

  get weekEnd() {
    if (!this.weekdays.length) {
      return null;
    }
    
    return this.weekdays[this.weekdays.length - 1];
  }

  get isPreviousWeekVisible() {
    return this.currentMonday.isBefore(this.weekdays[0], 'day');
  }
}
