import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Term, TermClientOverride } from '@app/interfaces';
import { map } from 'rxjs/operators';
import { Candidate } from '@app/models';

@Injectable()
export class ClientService {
  multiSite: boolean;

  constructor(private http: HttpService) {
    this.getClientSummary().subscribe(result => {
      this.multiSite = result.ClientDetails.ClientSites.length > 1;
    },
    error => console.log(error))
  }

  getClientSummary(): Observable<any> {
    let url: string = `/clients/summary`;
    return this.http.get(url);
  }

  getClientDetails(): Observable<any> {
    let url: string = `/clients/details`;
    return this.http.get(url);
  }

  getBookingTerms(clientId: string): Observable<Term[]> {
    let url: string = `/clients/${clientId}/terms`;
    return this.http.get(url).pipe(map(res => {
      let terms = [];
      if (res) {
        res.forEach(function(term) {
          terms.push(new Term().deserialize(term))
        })
      }

      return terms;
    }));
  }

  getTermsForSettings(clientId: string): Observable<Term[]> {
    let url: string = `/clients/${clientId}/terms/detailed`;
    return this.http.get(url).pipe(map(res => {
      let terms = [];
      if (res) {
        res.forEach(function(term) {
          terms.push(new Term().deserialize(term))
        })
      }

      return terms;
    }));
  }

  overrideTermRange(clientId: string, termRange: TermClientOverride): Observable<Term[]> {
    let url: string = `/clients/${clientId}/terms`;
    return this.http.post(url, termRange).pipe(map(res => {
      let terms = [];
      if (res) {
        res.forEach(function(term) {
          terms.push(new Term().deserialize(term))
        })
      }

      return terms;
    }));
  }

  getInvoices(pageNr: number = 0, pageSize: number = 50): Observable<any> {
    let url: string = `/clients/invoices/${pageNr}/${pageSize}`;
    return this.http.get(url);
  }

  getInvoice(id: any): Observable<any> {
    let url: string = `/clients/invoices/${id}`;
    return this.http.get(url);
  }

  getInvoiceDetails(number: any): Observable<any> {
    let url: string = `/clients/invoices/${number}/signoffdetails`;
    return this.http.get(url);
  }

  getBalance(): Observable<any> {
    let url: string = `/clients/invoices/amount/unpaid`;
    return this.http.get(url);
  }

  getReference(referenceId): Observable<any> {
    let url: string = `/clients/reference/${referenceId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Api-Version': '1.0'
    });

    return this.http.getNoLoggedIn(url, {headers: headers});
  }

  updateReference(reference): Observable<any> {
    let url: string = `/clients/reference/updateDetails`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Api-Version': '1.0'
    });

    return this.http.postNoLoggedIn(url, reference, {headers: headers});
  }

  getCandidateDetailsForBooking(candidateIds: string[]): Observable<Candidate[]> {
    let url: string = `/clients/GetCandidateDetailsForBooking`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Api-Version': '1.0'
    });

    return this.http.post(url, { CandidateIds: candidateIds }, {headers: headers}).pipe(
      map(result => { 
        let candidates: Candidate[] = [];

        result.forEach(candidate => candidates.push(new Candidate().deserialize(candidate)));

        return candidates;
      })
    );
  }
}
