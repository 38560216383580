<ul class="dp-time-select-controls">
  <li class="dp-time-select-control dp-time-select-control-hours">
    <button type="button"
            class="dp-time-select-control-up"
            [disabled]="!showIncHour"
            (click)="increase('hour')">
    </button>
    <span class="dp-time-select-display-hours"
          [innerText]="hours">
    </span>
    <button type="button"
            class="dp-time-select-control-down"
            [disabled]="!showDecHour"
            (click)="decrease('hour')"></button>
  </li>
  <li class="dp-time-select-control dp-time-select-separator"
      [innerText]="componentConfig.timeSeparator">
  </li>
  <li class="dp-time-select-control dp-time-select-control-minutes">
    <button type="button"
            class="dp-time-select-control-up"
            [disabled]="!showIncMinute"
            (click)="increase('minute')"></button>
    <span class="dp-time-select-display-minutes"
          [innerText]="minutes">
    </span>
    <button type="button"
            [disabled]="!showDecMinute" class="dp-time-select-control-down"
            (click)="decrease('minute')"></button>
  </li>
  <ng-container *ngIf="componentConfig.showSeconds">
    <li class="dp-time-select-control dp-time-select-separator"
        [innerText]="componentConfig.timeSeparator">
    </li>
    <li class="dp-time-select-control dp-time-select-control-seconds">
      <button type="button"
              class="dp-time-select-control-up"
              [disabled]="!showIncSecond"
              (click)="increase('second')"></button>
      <span class="dp-time-select-display-seconds"
            [innerText]="seconds">
      </span>
      <button type="button"
              class="dp-time-select-control-down"
              [disabled]="!showDecSecond"
              (click)="decrease('second')"></button>
    </li>
  </ng-container>
  <li class="dp-time-select-control dp-time-select-control-meridiem" *ngIf="!componentConfig.showTwentyFourHours">
    <button type="button"
            class="dp-time-select-control-up"
            [disabled]="!showToggleMeridiem"
            (click)="toggleMeridiem()"></button>
    <span class="dp-time-select-display-meridiem"
          [innerText]="meridiem">
    </span>
    <button type="button"
            class="dp-time-select-control-down"
            [disabled]="!showToggleMeridiem"
            (click)="toggleMeridiem()"></button>
  </li>
</ul>
