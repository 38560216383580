import { Injectable } from '@angular/core';

@Injectable()
export class FilterService {
    availabilityDays: any;
    jobType: number;
    experience: number;
    savedDisplayDates: any;

    candidates: any;
    scrollPosition: number;
    pageNumber: number;
    containerHeight: number;
}