import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class VideoComponent implements OnInit {
  @Input() videoEmbedHtml: string;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  getVideoEmbedHtml() {
    return this.sanitizer.bypassSecurityTrustHtml(this.videoEmbedHtml);
  }  

}
