import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService, AppTitle } from '@modules/_shared';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  userInfo: any;

  constructor(private headerService: HeaderService,
              private activatedRoute: ActivatedRoute ) {
    this.activatedRoute.data.subscribe(data => {
      this.userInfo = data.user;
    });
  }

  ngOnInit() {
    this.headerService.changeHeader(<AppTitle>{
      userInfo: this.userInfo 
    });
  }
  
}
