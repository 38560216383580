import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceService, DeviceTypes } from '@services/device.service';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[device]'
})
export class DeviceDirective implements OnInit {
  constructor(
    private device: DeviceService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.device.typeChange.pipe(
      // debounceTime(100)
    ).subscribe((type: DeviceTypes) => {
      this.device.type = type;
      this.renderer.removeClass(this.element.nativeElement, 'mobile');
      this.renderer.removeClass(this.element.nativeElement, 'tablet');
      switch (this.device.type) {
        case this.device.types.mobile: {
          this.renderer.addClass(this.element.nativeElement, 'mobile');
          break;
        }
        case this.device.types.tablet: {
          this.renderer.addClass(this.element.nativeElement, 'tablet');
          break;
        }
        default: {

        }
      }
    });
  }

}
