<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png">
    </div>

    <form [formGroup]="changePasswordForm" [class.submitted]="submitted" (ngSubmit)="onSubmit()" novalidate
      class="sign-form">
      <h4 class="form-head">Set your new password</h4>

      <p class="help-text">
        Please ensure your password is, 8 characters minimum,
        includes at least one uppercase character, and one number.
      </p>

      <div class="form-group has-float-label" pVisibility>
        <input autocomplete="new-password" autofocus="true" class="form-control" id="input-n_password" type="password"
          formControlName="newPassword" placeholder="New password" app-validate-equal="confirmPassword" reverse="true"
          required [ngClass]="{'sign-error-input': serverError ||
          (changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').hasError('passwordStrength')) ||
          (changePasswordForm.get('confirmPassword').hasError('validateEqual'))}">

        <label for="input-n_password" [ngClass]="{'sign-error-label': serverError ||
        (changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').hasError('passwordStrength')) ||
        (changePasswordForm.get('confirmPassword').hasError('validateEqual'))}">New
          password</label>
      </div>

      <div class="form-group has-float-label" pVisibility>
        <input autocomplete="confirm-password" class="form-control" id="input-c_password" type="password"
          formControlName="confirmPassword" placeholder="Confirm new password" app-validate-equal="newPassword" required
          [ngClass]="{'sign-error-input': serverError || (changePasswordForm.get('confirmPassword').hasError('validateEqual') && confirmPasswordFocus)}"
          (focus)="confirmPasswordFocus = true">

        <label for="input-c_password"
          [ngClass]="{'sign-error-label': serverError || (changePasswordForm.get('confirmPassword').hasError('validateEqual') && confirmPasswordFocus)}">Confirm
          new password</label>
      </div>

      <div class="form-footer">
        <div class="error-msg"
          *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').hasError('passwordStrength') && confirmPasswordFocus">
          <i class="m-icon va-m">info</i>
          Please ensure your password is, 8 characters minimum,
          includes at least one uppercase character, and one number.
        </div>

        <div class="text-center error-msg"
          *ngIf="changePasswordForm.get('confirmPassword').hasError('validateEqual') && confirmPasswordFocus">
          <i class="m-icon va-m">info</i>
          Passwords do not match.
        </div>

        <div *ngIf="serverError" class="text-center error-msg">
          <i class="m-icon va-m">info</i>
          The email or password is incorrect.
        </div>

        <button [disabled]="loading || !changePasswordForm.valid" type="submit" class="btn btn-primary"
          [style.background]="loading ? '#0C86B3' : '#20C4FF'" [style.borderColor]="loading ? '#0C86B3' : '#20C4FF'"
                (click)="logEventForSetPassword()">Set password</button>
      </div>
    </form>

    <div class="outside-footer"></div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>

</div>
