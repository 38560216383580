import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logc-questionnaire',
  templateUrl: './logc-questionnaire.component.html',
  styleUrls: ['./logc-questionnaire.component.scss']
})
export class LogcQuestionnaireComponent implements OnInit {
  @Input() reference: any;
  @Input() disabled: boolean = false;

  @Input() submitLoader: boolean = false;
  @Input() onUpdateReference: Function;
  @Input() submissionError: boolean = false;
  
  isNotValid: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  updateReference() {
    this.validateControls();

    if (this.isNotValid) {
      return;
    }

    this.onUpdateReference();
  }

  validateControls() {
    this.isNotValid = false;
    if (!this.reference.Role) {
      this.isNotValid = true;
    }
    if (!this.reference.FirstName) {
      this.isNotValid = true;
    }
    if (!this.reference.JobTitle) {
      this.isNotValid = true;
    }
    if (!this.reference.OrganisationName) {
      this.isNotValid = true;
    }
  }

  showConfirmationStatement(): boolean {
    if (!this.reference) {
      return false;
    }

    return this.disabled && this.reference.Status >= 3;
  }

}
