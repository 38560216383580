import { Component, EventEmitter, Inject, Input, OnInit, Output, inject } from '@angular/core';
import { NavListComponent } from '@components/nav-list/nav-list.component';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FEATURE_FLAGS_SERVICE, FEATURE_FLAG } from '@app/interfaces';
import { Observable } from 'rxjs';
import { LocalizedFeatureFlagsService } from '@app/localization/services/localized-feature-flags.service';

@Component({
  selector: 'app-account-nav-list',
  templateUrl: './account-nav-list.component.html',
  styleUrls: [
    '../nav-list.component.scss',
    './account-nav-list.component.scss'
  ]
})
export class AccountNavListComponent extends NavListComponent implements OnInit {
  @Output() logout = new EventEmitter();
  @Output() changeImage = new EventEmitter();
  @Input() faceImageUrl = '';
  @Input() hasClientAdministratorPermission = new Observable<boolean>();
  notificationSettingsPermission: boolean; //to consolidate this into a proper service together with permission flags

  analytics = getAnalytics();

  constructor(
    protected router: Router,
    @Inject(FEATURE_FLAGS_SERVICE) public featureFlagService: LocalizedFeatureFlagsService
  ) {
    super(router);
  }

  get FEATURE_FLAG() {
    return FEATURE_FLAG;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.notificationSettingsPermission = await this.featureFlagService.getFeatureFlag(FEATURE_FLAG.NOTIFICATION_SETTINGS);
  }

  logEventTitleUpdateProfilePicture() {
    logEvent(this.analytics, 'click_title_profilepic');
  }

  logEventTitleCompletedJobs() {
    logEvent(this.analytics, 'click_title_completedjobs');
  }

  logEventTitleInvoices() {
    logEvent(this.analytics, 'click_title_invoices');
  }

  logEventTitleManageStaff() {
    logEvent(this.analytics, 'click_title_managestaff');
  }

  logEventTitleLogout() {
    logEvent(this.analytics, 'click_title_logout');
  }

  logEventSettings() {
    logEvent(this.analytics, 'click_title_settings');
  }
}
