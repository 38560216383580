import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { QueryEncoderHelper } from '../helpers';
import { HttpService } from './http.service';

@Injectable()
export class RegistrationService {

  constructor(private http: HttpService) {
  }

  getLoggedInUserDetails(): Observable<any> {
    let url: string = `/account/GetDetailedLoggedInUserDetails`;
    return this.http.get(url);
  }

  getCurrentUserPrimaryProfile(token: any): Observable<any> {
    let url: string = `/UserRegistration/GetCurrentUserPrimaryProfile`;
    const headers = new HttpHeaders({
      'Authorization': `bearer ${token}`
    });
    return this.http.getNoLoggedIn(url, {headers: headers});
  }

  registerUser(newUser: any): Observable<any> {
    let url: string = `/UserRegistration/Signup`;
    return this.http.postNoLoggedIn(url, newUser);
  }

  // get questions
  getRoleTags(jobRoleId: string): Observable<any> {
    let url: string = `/clients/vacancies/roles/${jobRoleId}/tags`;
    return this.http.getNoLoggedIn(url);
  }

  addCVAssetForDefaultProfile(assetId: number): Observable<any> {
    let url: string = `/UserRegistration/AddCVAssetForDefaultProfile?assetId=${assetId}`;
    return this.http.postNoLoggedIn(url, assetId);
  }

  uploadCV(file: any, token: string): Observable<any> {
    let url: string = `/candidate/UploadCV`;
    const headers = new HttpHeaders({
      'Authorization': `bearer ${token}`
    });
    const formdata: FormData = new FormData();
    formdata.append('mediaFile', file);
    return this.http.postNoLoggedIn(url, formdata, {headers: headers});
  }

  getToken(username, password): Observable<any> {
    let params = new URLSearchParams('');
    params.set('username', username);
    params.set('password', password);
    params.set('client_id', environment.loginBaseConf.client_id);
    params.set('client_secret', environment.loginBaseConf.client_secret);
    params.set('grant_Type', environment.loginBaseConf.Grant_Type);
    return this.http.postToken(params.toString());
  }

  postTypeOfWOrk(token: any, workyTpes: any): Observable<any> {
    let url: string = `/UserRegistration/AddTypeOfWorkForDefaultProfile`;
    const headers = new HttpHeaders({
      'Authorization': `bearer ${token}`
    });
    return this.http.postNoLoggedIn(url, workyTpes, {headers: headers});
  }

  sendAnswers(token: string, answers: any): Observable<any> {
    let url: string = `/UserRegistration/AddTagsForDefaultProfile`;
    const headers = new HttpHeaders({
      'Authorization': `bearer ${token}`
    });
    return this.http.postNoLoggedIn(url, answers, {headers: headers});
  }

  jobApply(token: any, jobUid: string, source?: string): Observable<any> {
    let url: string = `/job/apply/${jobUid}?applyVia=2&source=${source}`;
    const headers = new HttpHeaders({
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json',
      'Api-Version': '1.0'
    });
    return this.http.getNoLoggedIn(url, {headers: headers});
  }

  verifyEmail(userId: string, token: string): Observable<any> {
    let url: string = `/UserRegistration/VerifyEmail?userId=${userId}&token=${token.toEncode()}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.getNoLoggedIn(url, {headers: headers}).pipe(result => result);
  }

  getPublicJobDetails(jobId: string): Observable<any> {
    let url: string = `/job/publicJobDetails/${jobId}`;
    return this.http.getNoLoggedIn(url);
  }

  getWorkRegions(): Observable<any> {
    let url: string = '/UserRegistration/GetWorkRegions';
    return this.http.getNoLoggedIn(url);
  }
}
