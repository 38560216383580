<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png">
    </div>

    <form [formGroup]="forgotPasswordForm" [class.submitted]="submitted" (ngSubmit)="onSubmit()" novalidate
      class="sign-form">
      <h4 class="form-head">Forgotten your password</h4>

      <p class="help-text">Enter your email address below and check your email for the reset password link.</p>

      <div class="form-group has-float-label">
        <input autofocus="true" autocomplete="email" class="form-control" id="input-email" type="email"
          formControlName="opogousr" placeholder="Email" required
          [ngClass]="{'sign-error-input': serverError || notFound}">

        <label for="input-email" [ngClass]="{'sign-error-label': serverError || notFound}">Email</label>
      </div>

      <div class="form-footer">
        <div *ngIf="notFound" class="error-msg">
          <i class="m-icon va-m">info</i>
          Email address does not exist
        </div>

        <div *ngIf="serverError" class="text-center error-msg">
          <i class="m-icon va-m">info</i>
          The email or password is incorrect.
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="!forgotPasswordForm.valid"
                (click)="logEventForGetNewPassword()">Get new password</button>
      </div>
    </form>

    <div class="outside-footer"></div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>
</div>
