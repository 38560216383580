import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[app-validate-equal][formControlName],[app-validate-equal][formControl],[app-validate-equal][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true}
  ]
})
export class EqualValidator implements Validator {

  constructor(
    @Attribute('app-validate-equal') public validateEqual: string,
    @Attribute('reverse') public reverse: string) {
  }

  private get isReverse() {
    if (!this.reverse) {
      return false;
    }
    return this.reverse === 'true' ? true : false;
  }

  validate(control: AbstractControl): { [key: string]: any } {

    const value = control.value;

    const equalValue = control.root.value[this.validateEqual];
    const equalControl = control.root.get(this.validateEqual);

    if (equalValue && value !== equalValue && !this.isReverse) {
      return {validateEqual: true};
    }

    if (equalValue && value === equalValue && this.isReverse) {
      delete equalControl.errors['validateEqual'];
      if (!Object.keys(equalControl.errors).length) {
        equalControl.setErrors(null);
      }
    }

    if (equalValue && value !== equalValue && this.isReverse) {
      equalControl.setErrors({validateEqual: true});
    } else if (!equalValue) {
      equalControl.setErrors({validateEqual: true});
    }
    return null;
  }

}
