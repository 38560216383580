<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png" />
    </div>

    <form class="sign-form">
      <h4 class="form-head">Good work</h4>
      <p class="help-text">You have successfully changed your password.</p>
    </form>

    <div class="outside-footer"></div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>
</div>
