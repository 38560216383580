import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null;
  }

  const upperCaseCharacters = /[A-Z]+/g;
  // const lowerCaseCharacters = /[a-z]+/g;
  const numberCharacters = /[0-9]+/g;
  // const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (
    value.length < 8 ||
    upperCaseCharacters.test(value) === false ||
    // lowerCaseCharacters.test(value) === false ||
    numberCharacters.test(value) === false /* ||
    specialCharacters.test(value) === false */
  ) {
    return {
      passwordStrength: 'Please ensure your password is, 8 characters minimum, includes at least one uppercase character, and one number.'
    };
  }
};
