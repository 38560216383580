import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  AuthService,
  ClientService,
  LearnService,
  MobileService,
  OpogoService,
  RegistrationService
} from "@app/services";
import {
  PasswordStrengthValidatorSmall,
  ConfirmPasswordValidator
} from "../../../helpers";
import { environment } from "../../../../environments/environment";
import { default as Utils } from "../../../utils";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"]
})
export class SignUpComponent implements OnInit {
  showLoader: boolean = false;
  loggedIn: boolean;
  signIn: boolean;
  isSchool: boolean;
  isJobSeeker: boolean;

  sourceFrom: string;
  referredBy: string;

  signedUp: boolean;
  userToken: string;
  newPage: boolean = false;

  signUpForm: FormGroup;

  error : boolean = false;
  errorMsg: string;
  passwordValid: boolean = true;
  passwordsMatch: boolean = true;

  // sign in
  serverError: string;
  loginForm: FormGroup;

  userEmail: string = '';

  appStoreUrl: string;
  playStoreUrl: string;

  questionId = [
    {
      // Qualifications
      id: "B4DD9F9F-1EB3-4289-AC43-0D07E11061C4",
      questionText: "Which teaching qualifications do you have?"
    },
    {
      // Subject
      id: "C2EE36EB-EDD7-4998-98A1-85D31B1E36D4",
      questionText:
        "Which subjects have you been trained in or have experience teaching in?"
    },
    {
      // Special Educational Needs
      id: "DD3DFEB0-4674-4072-9446-C7597AF6C032",
      questionText:
        "Which Special Education Needs have you trained in or have experience teaching in?"
    },
    {
      // Age Groups
      id: "ED5A17E4-7717-4F90-A2EF-A3BAAFA6E812",
      questionText:
        "Which age groups have you been trained in or have experience teaching in?"
    }
  ];

  get HubSpotSignup() {
    return environment.hubspotSignup;
  }

  constructor(
    public opogoService: OpogoService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private clientService: ClientService,
    private learnService: LearnService,
    private mobileService: MobileService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      params = Utils.paramsKeyToLower(params);
      this.sourceFrom = params["source"];
      this.referredBy = params["referralid"];
    });

    this.appStoreUrl = this.mobileService.appStoreUrl;
    this.playStoreUrl = this.mobileService.playStoreUrl;
  }

  ngOnInit() {
    this.showLoader = true;
    this.loggedIn = this.isLoggedIn();

    if (this.loggedIn && this.auth.token != null) {
      this.userToken = this.auth.token;
      this.getSomeUserInfo(this.userToken);

    } else {
      this.showLoader = false;

      // sign up form
      this.signUpForm = new FormGroup(
        {
          FirstName: new FormControl("", {
            validators: [Validators.required],
            updateOn: "change"
          }),
          LastName: new FormControl("", {
            validators: [Validators.required],
            updateOn: "change"
          }),
          Email: new FormControl("", {
            validators: [Validators.required, Validators.email],
            updateOn: "blur"
          }),
          Password: new FormControl("", {
            validators: [
              Validators.required,
              Validators.minLength(6),
              Validators.maxLength(100),
              PasswordStrengthValidatorSmall
            ],
            updateOn: "change"
          }),
          ConfirmPassword: new FormControl("", {
            validators: [Validators.required],
            updateOn: "blur"
          }),
          Consent: new FormControl("", {
            validators: [Validators.required],
            updateOn: "change"
          }),
          Marketing: new FormControl("", {
            validators: [],
            updateOn: "change"
          }),
          Source: new FormControl(this.sourceFrom, {
            validators: []
          }),
        },
        {
          validators: [ConfirmPasswordValidator.MatchPassword],
          updateOn: "blur"
        }
      );

      // log in form
      this.loginForm = new FormGroup(
        {
          opogousr: new FormControl("", {
            validators: [Validators.required, Validators.email],
            updateOn: "blur"
          }),
          opogopsw: new FormControl("", {
            validators: [Validators.required],
            updateOn: "blur"
          })
        },
        {
          updateOn: "change"
        }
      );
    }
  }

  isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  getSomeUserInfo(token: string) {
    this.showLoader = true;
    this.registrationService.getCurrentUserPrimaryProfile(token).subscribe(
      result => {
        this.showLoader = false;
        if (this.userToken) {
          if (!this.sourceFrom) {
            this.sourceFrom = undefined;
          }
        }
      },
      error => {
        this.showLoader = false;
      }
    );
  }

  onSignUp() {
    this.userEmail = this.signUpForm.controls["Email"].value;
    
    this.signUpForm.get("Password").hasError("passwordStrength")
      ? (this.passwordValid = false)
      : (this.passwordValid = true);
    this.signUpForm.get("Password").value ===
      this.signUpForm.get("ConfirmPassword").value
      ? (this.passwordsMatch = true)
      : (this.passwordsMatch = false);
    this.signUpForm.get("Marketing").value
      ? this.signUpForm.controls["Marketing"].setValue(true)
      : this.signUpForm.controls["Marketing"].setValue(false);

    if (this.signUpForm.valid) {
      this.showLoader = true;
      this.error = false;
        
      this.registrationService.registerUser(this.signUpForm.value).subscribe(
        result => {
          if (result) {
            this.signedUp = true;
            this.showLoader = false;

            this.newPage = false;

            this.retrieveToken(
              this.signUpForm.get("Email").value,
              this.signUpForm.get("Password").value
            );
            window.scrollTo(0, 0);
          }
        },
        error => {
          this.showLoader = false;
          console.log("register error:", error);
          if (error.error.ModelState.Errors.length > 0) {
            this.errorMsg = error.error.ModelState.Errors[0];
          }
        }
      );
    } else {
      this.error = true;
      this.errorMsg = 'Please fill out the fields highlighted in red above';
    }
  }

  toggleSignIn() {
    this.signIn = !this.signIn;
    window.scrollTo(0, 0);
  }

  retrieveToken(email: string, password: string) {
    this.registrationService.getToken(email, password).subscribe(response => {
      this.userToken = response.access_token;
      this.logInUser(
        this.signUpForm.get("Email").value,
        this.signUpForm.get("Password").value
      );
    });
  }

  onLogin(): void {
    this.showLoader = true;

    if (this.loginForm.valid) {
      this.signInUser(
        this.loginForm.get("opogousr").value,
        this.loginForm.get("opogopsw").value
      );
    }
  }

  signInUser(email, password): void {
    this.showLoader = true;
    this.auth
      .login(email, password)
      .then(reponse => {
        this.showLoader = false;
        this.redirectUser();
        if (this.auth.token != null) {
          this.userToken = this.auth.token;
          this.signIn = true;
        }
      })
      .catch(error => {
        this.showLoader = false;
        this.serverError = error.error.error_description;
        console.log("login error", error);
      });
  }

  redirectUser() {
    this.clientService.getClientSummary().subscribe(
      result => {
        this.isSchool = result.ClientId ? true : false;
        if (this.isSchool && !this.newPage) {
          window.top.location.href = this.opogoService.appUrl;
        } else {
          this.registrationService.getLoggedInUserDetails().subscribe(
            result => {
              this.isJobSeeker = result.IsJobSeeker;
              if (this.isJobSeeker && !this.newPage) {
                window.top.location.href = this.opogoService.websiteUrl;
              } else if(!this.newPage) {
                window.top.location.href = this.opogoService.websiteUrl;
              }
            },
            error => {
              console.log("Could not get LoggedInUserDetails", error);
            }
          );
        }
      },
      error => {
        this.serverError = error.error.error_description;
      }
    );
  }

  logInUser(email, password): void {
    this.showLoader = true;
    this.auth
      .login(email, password)
      .then(reponse => {
        this.loggedIn = true;
        this.showLoader = false;

        if (this.auth.token != null) {
          this.userToken = this.auth.token;
          this.redirectToCommunity();
          this.signIn = false;
          this.getSomeUserInfo(this.userToken);
        }
      })
      .catch(error => {
        this.showLoader = false;
        this.serverError = error.error.error_description;
        console.log("login error", error);
      });
  }

  redirectToCommunity() {
    // No idea what this is... But keep the existing functionlity...
    if (this.newPage)
      return;

    this.learnService.gotoLearn(false, this.userToken);
  }

  getArray(count) {
    return new Array(count).fill(0).map((x, i) => i);
  }
}
