import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Text } from '@app/localization/providers/localized-text.provider';
import { getAvailabilityTypeText } from '@app/models';

export type DISABLED = 'disabled';
export type UNAVAILABLE = 'unavailable';
export type BOOKEDATCLIENT = 'booked-at-client';
export type AVAILABLE = 'available';

export type WidgetStatus = DISABLED | UNAVAILABLE | BOOKEDATCLIENT | AVAILABLE;

@Component({
  selector: 'app-booking-widget-circle',
  templateUrl: './booking-widget-circle.component.html',
  styleUrls: ['./booking-widget-circle.component.scss']
})
export class BookingWidgetCircleComponent {
  protected readonly getAvailabilityTypeText = getAvailabilityTypeText;
  @Input() COMPONENT_PREFIX = 'bwc';
  @Input() showAnimation: boolean;

  @Input() candidateId: string;
  @Input() termOnly: boolean;
  @Input() widgetStatus: WidgetStatus = 'available';
  @Input() termlyUnavailableForSelectedJobRole: boolean;

  @Output() onClickTrigger = new EventEmitter();

  localizedText = Text;

  onClick() {
    this.onClickTrigger.emit();
  }

  getLabelClasses() {
    let classes = [];

    if (this.widgetStatus) {
      classes.push(this.widgetStatus.toString());
    }

    if (this.termOnly) {
      classes.push('term-only')
    }

    if (this.showAnimation) {
      classes.push('loading-animation');
    }

    return classes;
  }
}
