<div
  class="mobile-menu-component"
  [ngClass]="{
    'd-block': menuService.menuRightVisible
  }"
>
  <!-- main menu - mobile - left side  -->
  <div
    class="menu-right d-lg-none row no-gutters"
    [ngClass]="{
      'menu-right-on': menuService.menuRightVisible
    }"
  >
    <div class="menu-right-side col-auto">
      <button class="menu-toggler p-4" type="button" (click)="showHideRightMenu()">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>

    <div class="col">
      <div class="row no-gutters mobile-menu-header px-4 py-3 mb-4">
        <div class="col-auto profile-image">
          <div *ngIf="defaultCandidatePhotoUrl" class="square op-avatar"></div>
          <img *ngIf="!defaultCandidatePhotoUrl" src="{{userInfo.ProfileImageUrl}}"/>
        </div>
        <div class="col user-name px-3">{{userName}}</div>
      </div>
      <div class="row no-gutters px-4 pt-2">
        <div class="col-12" *ngIf="hasJobBookingPermission">
          <a
            [routerLink]="['/add-job']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ match: ['/add-job'] }"
            (click)="showHideRightMenu()"
            >Book Supply</a>
        </div>
        <div class="col-12" *ngIf="hasJobBookingPermission">
          <a
            [routerLink]="['/manage-jobs']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ match: ['/manage-jobs'] }"
            (click)="showHideRightMenu()"
            >Manage jobs & Timesheets</a>
        </div>        
        <div class="col-12" *ngIf="hasTalentPoolUsePermission">
          <a
            [routerLink]="['/talent']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ match: ['/talent'] }"
            (click)="showHideRightMenu()"
            >Talent</a>
        </div>

        <div class="col-12" *ngIf="hasContentViewingPermission">
          <a
            [ngClass]="{ active: showOpogoHub }"
            href="{{ joinCommunityLink }}"
            target="_blank"
            (click)="showHideRightMenu()"
            >Learn</a>
        </div>

        <div class="col-12" *ngIf="hasAccountsPermission">
          <a
            [routerLink]="['/account/invoices']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ match: ['/account/invoices'] }"
            (click)="showHideRightMenu()"
            >Invoices</a
          >
        </div>

        <div class="col-12">
          <a [ngClass]="{'active' : router.isActive('/settings', false)}"
             (click)="showHideSettingsMenu()">Settings</a>
        </div>

        <div class="col-12 submenu" *ngIf="showSettingsMenu">
          <a [routerLink]="['/settings/notifications']"
             [ngClass]="{'active' : router.isActive('/settings', true) || router.isActive('/settings/notifications', true)}"
             (click)="showHideRightMenu()">Personal Notifications</a>
        </div>

        <div class="col-12 submenu" *ngIf="showSettingsMenu">
          <a [routerLink]="['/settings/chat']"
             [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{match:['/settings/chat']}"
             (click)="showHideRightMenu()">School Chat</a>
        </div>

        <div class="col-12" *ngIf="hasClientAdministratorPermission">
          <a
            [routerLink]="['/tutorials']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ match: ['/tutorials'] }"
            (click)="showHideRightMenu()"
            >Tutorials</a
          >
        </div>

        <div class="col-12">
          <a [routerLink]="['/login']" (click)="showHideRightMenu()">Logout</a>
        </div>


      </div>
    </div>
  </div>

</div>
