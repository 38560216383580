import { Component, Input, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationIcon, NotificationsService } from '@app/services/notifications.service';
import { PushNotificationsService } from '@app/services/push-notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Input() isLink: boolean = false;
  @Input() public onOpenModal?: Function;

  modalRef: BsModalRef;
  notifications: any[];
  notificationsConfig: any; 
  NotificationIcon = NotificationIcon;
  subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private notificationService: NotificationsService,
    private router: Router) { 
      this.notificationsConfig = this.notificationService.notificationsConfig;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub && sub.unsubscribe && (typeof sub.unsubscribe === 'function') && sub.unsubscribe();
    });

  }

  ngOnInit() {
    this.notifications = [...this.notificationService.notifications];

    const refreshNotificationSubscription = this.notificationService.RefreshNotificationEvent.subscribe(() => {  
      this.notifications = [...this.notificationService.notifications];
    });

    this.subscriptions.push(refreshNotificationSubscription);
  }

  getNotificationsCount() {
    if (this.notificationService.notifications.length === 0) {
      return null;
    }
    return this.notificationService.notifications.length > 9 ? "9+" : `${this.notificationService.notifications.length}`;
  }

  open(template: TemplateRef<any>) {
    let className:string = this.isLink ? 'notifications-modal mobile' : 'notifications-modal';

    this.modalRef = this.modalService.show(template,  {...{class: className}});
    if (this.onOpenModal) {
      this.onOpenModal();
    }
  }

  navigateTo(notification: any) {
    let target:string = this.notificationsConfig.get(notification.message.Type).link;
    this.router.navigate([target], { queryParams: { candidateId: notification.message.CandidateId }});
    this.modalRef.hide();
  }

  deleteNotification(notification: any) {
    this.notificationService.deleteNotification(notification);
  }

  deleteAllNotifications() {
    this.notificationService.deleteAllNotifications();
    this.modalRef.hide();
  }

}
