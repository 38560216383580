import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ClientService, OpogoService } from '@app/services';
import { DatePipe } from '@angular/common';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
  referenceId: any;
  reference: any;
  submitted: boolean = false;
  disabled: boolean = false;
  error: boolean = false;
  key: any;
  isNotValid: boolean = false;
  submitLoader: boolean = false;
  submissionError: boolean = false;

  Reference = "Reference";
  LOGC = "LOGC";
  CharacterReference = "CharacterReference";

  currentDate: Dayjs = dayjs()
  referencePeriod: string;

  constructor(
    public opogoService: OpogoService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private title: Title
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.referenceId = params['id'];
      this.key = params['key'];

      this.disabled = (this.key === undefined);
    });
  }

  ngOnInit() {
    if (this.referenceId) {
      this.clientService.getReference(this.referenceId).subscribe(
        result => {
          this.reference = result;
          this.title.setTitle(`${this.reference.ReferenceType.Description} for ${this.reference.Candidate.FullName}`);

          this.setReferencePeriod();

          if (!this.disabled) {
            if (this.reference.ReferenceType.Name === this.Reference) {
              this.submitted = this.reference.ScorePupilRelationship !== null;
            }
            else if (this.reference.ReferenceType.Name === this.LOGC) {
              this.submitted = this.reference.Role !== null;
            } else {
              this.submitted = this.reference.AdditionalNotes !== null;
            }
          }
        },
        error => {
          this.error = true;
          console.log(error);
        }
      );
    }
  }

  onUpdateReference() {

    this.submitLoader = true;
    this.submissionError = false;

    this.reference.key = this.key;
    this.clientService.updateReference(this.reference).subscribe(
      result => {
        this.submitted = true;
        this.submitLoader = false;
      },
      error => {
        console.log(error);
        this.submitLoader = false;
        this.submissionError = true;
      }
    );
  }

  setReferencePeriod() {
    let datePipe = new DatePipe('en_GB');

    if (this.reference.ReferenceType.Name !== this.CharacterReference && this.reference.DateFrom && this.reference.DateTo) {
      this.referencePeriod = `${datePipe.transform(this.reference.DateFrom, 'dd MMMM yyyy')} to ${datePipe.transform(this.reference.DateTo, 'dd MMMM yyyy')}`;
    }
    else if (this.reference.ReferenceType.Name === this.CharacterReference && this.reference.DateFrom && !this.disabled) {
      this.referencePeriod = `Whom you've known since ${datePipe.transform(this.reference.DateFrom, 'dd MMMM yyyy')}`;
    }
    else if (this.reference.DateFrom && !this.reference.DateTo) {
      this.referencePeriod = `${datePipe.transform(this.reference.DateFrom, 'dd MMMM yyyy')}`;
    }
  }
}
