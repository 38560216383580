import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { ExpandableListComponent } from './expandablelist/expandable-list.component';
import { ExpandableListItemComponent } from './expandablelist/expandable-list-item.component';
import { DayJsDurationPipe } from '../../pipes/dayjs-duration.pipe';
import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { DayJsFormatPipe } from '@app/pipes/dayjs-format.pipe';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { MonthPickerPopupComponent } from './month-picker-popup/month-picker-popup.component';
import { StepsProgressComponent } from './steps-progress/steps-progress.component';
import { VideoComponent } from './video/video.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TabComponent,
    TabsComponent,
    ExpandableListComponent,
    ExpandableListItemComponent,
    DayJsDurationPipe,
    DayJsFormatPipe,
    CircularProgressComponent,
    ToggleSwitchComponent,
    MonthPickerPopupComponent,
    StepsProgressComponent,
    VideoComponent
  ],
  exports: [
    TabComponent,
    TabsComponent ,
    ExpandableListComponent,
    ExpandableListItemComponent,
    DayJsDurationPipe,
    DayJsFormatPipe,
    CircularProgressComponent,
    ToggleSwitchComponent,
    MonthPickerPopupComponent,
    StepsProgressComponent,
    VideoComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class CommonControlsModule { }
