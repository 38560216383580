import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DetailedTagCategory, JobRole, JobRoleGroup } from '@app/models/jobrole.model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { JobRoleTree, TagTree } from '@app/models';

export interface JobRoleSetupData {
  RoleGroups: JobRoleGroup[];
  JobRoles: JobRole[];
  TagCategories: DetailedTagCategory[];
}

export class JobRoleConfig {
  JobRoleSetupData: JobRoleSetupData;

  getJobRoleTreeSimple(): JobRoleTree {
    let jobRoleTree = JobRoleTree.create();

    jobRoleTree.setItems(this.JobRoleSetupData.JobRoles.filter(jr => !jr.IsQuickSelect));

    return jobRoleTree;
  }

  getJobRoleTreeWithTopRoles(): JobRoleTree {
    let jobRoleTree = JobRoleTree.create();
    
    jobRoleTree.setItems(this.JobRoleSetupData.JobRoles);

    return jobRoleTree;
  }

  getJobRoleGroups(): JobRoleGroup[] {
    return [...this.JobRoleSetupData.RoleGroups.sort((a,b) => a.OrderNo - b.OrderNo)];
  }

  getAllTagCategories(): TagTree {
    let tagTree = TagTree.create();

    tagTree.setItems(this.JobRoleSetupData.TagCategories);

    return tagTree;
  }

  getRoleGroupsTagCategoryLookup(): { [k: number]: TagTree } {
    let tagCategoryLookup = {};

    this.JobRoleSetupData.RoleGroups.forEach(roleGroup => {
      let tagTree = TagTree.create();
      let tags = this.JobRoleSetupData.TagCategories.filter(tc => tc.RoleGroups.includes(roleGroup.Id));
      tagTree.setItems(tags);
      tagCategoryLookup[roleGroup.Id] = tagTree;
    });

    return tagCategoryLookup;
  }

  getJobRolesTagCategoryLookup(): { [k: string]: TagTree } {
    let tagCategoryLookup = {};

    this.JobRoleSetupData.JobRoles.forEach(jobRole => {
      let jobRoleIds = [jobRole.Id].concat(jobRole.JobRoles.map(jr => jr.Id));

      jobRoleIds.forEach(jrId => {
        let tagTree = TagTree.create();
        let tags = this.JobRoleSetupData.TagCategories.filter(tc => tc.JobRoles.some(jr => jr === jrId));
        tagTree.setItems(tags);
        tagCategoryLookup[jrId] = tagTree;
      });
    });

    return tagCategoryLookup;
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobSetupService {
  public $roleConfig: BehaviorSubject<JobRoleConfig> = new BehaviorSubject(null);

  constructor(private http: HttpService) {
    this.getJobRoleSetupData()
      .pipe(
        tap(jobRoleSetupResult => {
          let jobRoleConfig = new JobRoleConfig();

          jobRoleConfig.JobRoleSetupData = jobRoleSetupResult;

          this.$roleConfig.next(jobRoleConfig);
        })
      )
      .subscribe();
  }

  private getJobRoleSetupData() : Observable<JobRoleSetupData> {
    let url: string = '/clients/jobrolesetup';

    return this.http.get(url);
  }
}
