import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { DatesFilterRequest } from '@app/interfaces';

@Injectable()
export class DateService {

  constructor(private http: HttpService) { }

  getFilteredWorkingDates(filterRequest: DatesFilterRequest): Observable<Dayjs[]> {
    let url: string = '/dates/workingdays/filtered';
    return this.http.post(url, filterRequest).pipe(
      map(res => {
       let dates = [];
       
       res.forEach(function (date) {
        dates.push(dayjs(date, 'YYYY-MM-DD'));
       });

       return dates;
      }
    ))
  }
}
