import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


import { HttpService } from './http.service';
import { AuthService } from '@services/auth.service';

@Injectable()
export class ContentService {

  constructor(
    private http: HttpService,
    private auth: AuthService
  ) {
  }

  getContentDetails(id: number): Observable<any> {
    let url: string = `/contents/getContentDetailsForWebApp/${id}`;
    return this.http.get(url);
  }

  getCandidateContentsPaginated(offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/getCandidateContentsPaginated?offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getLatestContents(offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/latestContentPaginated?offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContentByAuthor(authorName: string, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/latestContentPaginated?authorName=${authorName}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContentByAuthorAndType(authorName: string, contentType?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/contentByType?authorName=${authorName}&contentType=${contentType}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContentByType(contentType?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/contentByType?contentType=${contentType}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContentByTopic(contentTopicId?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/contentByTopic?contentTopicId=${contentTopicId}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getTopicsByType(contentType?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/topicsByType?contentType=${contentType}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContents(contentTopicId?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents?contentTopicId=${contentTopicId}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getContentByTypeAndTopic(contentType?: number, contentTopicId?: number, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/contentByTypeAndTopic?contentType=${contentType}&contentTopicId=${contentTopicId}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getPartnerContentList(offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/getPartnerContentList?offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getPartnerContentTypes(): Observable<any> {
    let url: string = `/contents/getPartnerContentTypes`;
    return this.http.get(url);
  }

  getPartnerTopics(): Observable<any> {
    let url: string = `/contents/getPartnerTopics`;
    return this.http.get(url);
  }

  getPartnersInMyNetwork(): Observable<any> {
    let url: string = `/contents/getPartnersInMyNetwork`;
    return this.http.get(url);
  }

  getPartnerContentVisibility(): Observable<any> {
    let url: string = `/contents/partnerGetContentVisibilityForWebApp`;
    return this.http.get(url);
  }

  getPartnerContent(id: number): Observable<any> {
    let url: string = `/contents/GetPartnerContentById/${id}`;
    return this.http.get(url);
  }

  uploadMedia(file): Observable<any> {
    let url: string = `/contents/PartnerUploadMedia`;

    const headers = new HttpHeaders({
      'Authorization': `bearer ${this.auth.token}`
    });

    const formdata: FormData = new FormData();
    formdata.append('mediaFile', file);

    return this.http.post(url, formdata, {headers: headers});
  }

  saveContent(content): Observable<any> {
    let url: string = `/contents/PartnerSaveContent`;
    return this.http.post(url, content);
  }

  publishContent(contentId): Observable<any> {
    let url: string = `/contents/PartnerPublishContent?contentId=${contentId}`;
    return this.http.get(url);
  }

  deleteContent(contentId): Observable<any> {
    let url: string = `/contents/PartnerDeleteContent?contentId=${contentId}`;
    return this.http.get(url);
  }

  submitContent(contentId): Observable<any> {
    let url: string = `/contents/PartnerSubmitContent?contentId=${contentId}`;
    return this.http.get(url);
  }

  retractContent(contentId): Observable<any> {
    let url: string = `/contents/PartnerRetractContent?contentId=${contentId}`;
    return this.http.get(url);
  }

  getCourses(offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/getCourses?offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getCoursesByAuthor(authorName: string, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/getCourses?authorname=${authorName}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getCoursesByTopic(topicId: any, offset: number = 0, limit: number = 50): Observable<any> {
    let url: string = `/contents/getCourses?contentTopicId=${topicId}&offset=${offset}&limit=${limit}`;
    return this.http.get(url);
  }

  getCourse(courseId: any): Observable<any> {
    let url: string = `/contents/getCourseById?id=${courseId}`;
    return this.http.get(url);
  }

  getTopics(): Observable<any> {
    let url: string = `/contents/getCourseTopics`;
    return this.http.get(url);
  }

  downloadCourseFile(id: any): Observable<any> {
    let url: string = `/contents/downloadCourseFile?id=${id}`;
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

  setLessonViewed(lessonId: any): Observable<any> {
    let url: string = `/contents/courseLessonSetViewed?lessonId=${lessonId}`;
    return this.http.get(url);
  }

  clearLessonViewed(lessonId: any): Observable<any> {
    let url: string = `/contents/courseLessonClearViewed?lessonId=${lessonId}`;
    return this.http.get(url);
  }
}
