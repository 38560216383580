import { Client } from '.';

export interface ClientContactSummary {
  ClientContactId: string;
  ClientId: string;
  ClientName: string;
  ClientSiteId: string;
  ClientSiteName: string;
  ServiceFee: number;
  Position: string;
  IsPartnerMember: boolean;
  PartnerLogoUrl: string;
  Timezone: string;
  Email: string;
  ClientDetails: Client;
  FirstName: string;
  LastName: string;
  OtherNames: string;
  TalentConsultant: Consultant;
  AccountManager: Consultant;
  Permissions: Permission[];
  ProfileImageUrl: string;
  PhoneNumber: string;
}

export interface Consultant {
  Id: string;
  AspNetUserId: string;
  Email: string;
  Forename: string;
  Surname: string;
  JobTitle: string;
  Mobile: string;
  OtherNames: string;
  ProfileImageUrl: string;
  ConsultantName: string;
  PhoneNumber: string;
}

export enum Permission {
  MobileUse = 1,
  WebUse = 2,
  ContentViewing = 3,
  JobSeeking = 4,
  JobBooking = 5,
  JobCheckingIn = 6,
  JobSigningOff = 7,
  JobPromo = 8,
  Invoice = 9,
  ContentCreation = 11,
  ClientAdministrator = 12,
  JobConfirmation = 14,
  TalentPool = 15,
  InvigTab = 21,
  TermBookings = 22
}
