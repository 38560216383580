import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators  } from '@angular/forms';

import { AuthService } from "../../../services";
import { default as Utils} from '../../../utils';

@Component({
  selector: 'app-embedded-forgotten-password',
  templateUrl: './embedded-forgotten-password.component.html',
  styleUrls: ['./embedded-forgotten-password.component.scss']
})

export class EmbeddedForgottenPasswordComponent implements OnInit {
  notFound: boolean = false;
  serverError: string;
  resetted: boolean;
  forgotPasswordForm: FormGroup;
  @ViewChild("email") emailInput: ElementRef;
  tokenHasExpired: boolean = false;

  constructor(private auth: AuthService, 
              private router: Router, 
              private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      params = Utils.paramsKeyToLower(params);
      this.tokenHasExpired = params['tokenhasexpired'];
    });
  }

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      'opogousr': new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      })
    }, 
    {
      updateOn: 'change'
    });

    // this.emailInput.nativeElement.focus();
  }

  onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      this.auth.resetPassword(this.forgotPasswordForm.get("opogousr").value).subscribe(
        result => {
          // console.log("ResetPassword", result);
          this.resetted = true;
          // this.router.navigate(['/forgot-password-email-confirmation']);
        }, 
        error => { 
          if (error.statusText === 'Not Found') {
            this.notFound = true;
          } else {
            this.serverError = error.statusText;
          }
          
        }
      );
    }
  }

}
