import { Component, Input, OnInit } from '@angular/core';
import { ChatRoom } from '@app/interfaces';
import { ChatService } from '@app/services';

@Component({
  selector: 'app-chatrooms',
  templateUrl: './chatrooms.component.html',
  styleUrls: ['./chatrooms.component.scss']
})
export class ChatroomsComponent implements OnInit {
  @Input() chatRooms: ChatRoom[];

  constructor(private chatService: ChatService) { }

  ngOnInit() {
  }

  isDefaultPicture(imageUrl?: string) {
    return imageUrl === null ||imageUrl === "" || imageUrl.includes('default-member-photo.png');
  }  

  selectChatRoom(chatRoom: ChatRoom) {
    this.chatService.selectChatRoom(chatRoom);
  }

}
