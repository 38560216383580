<div class="container-fluid loader-container" *ngIf="showLoader">
  <div class="row justify-content-center mb-20">
    <div class="col-12 px-md-0">
      <div class="row mb-3">
        <div class="col-12 text-center">
          <h2><span class="loader blue center"></span></h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sign-up-wrapper h-100">
  <!-- sign up form -->
  <div class="container sign-up-form" *ngIf="!loggedIn && !signIn && !signedUp">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-9 col-md-8 px-md-0">
        <div class="row mb-3">
          <div class="col-12 text-center">
            <h2>Join Opogo Learn</h2>
            <p>If you are only interested in accessing our Learn platform, set up <br>
              your account in less than 1 minute below!</p><br>
            <p>If you are looking for work with Opogo instead <a [href]="HubSpotSignup" target="_top">click here</a></p>
          </div>
        </div>
      </div>
    </div>
  
    <form [formGroup]="signUpForm" (ngSubmit)="onSignUp()" novalidate >
      <div class="row justify-content-center">
        <div class="col-12 col-sm-9 col-md-6 px-md-0">
    
          <div class="row mb-20">
            <div class="col-12 col-sm-6 pr-md-2"><input formControlName="FirstName" type="text" placeholder="First name" required [ngClass]="{'isValid': error }"></div>
            <div class="col-12 col-sm-6 pl-md-2"><input formControlName="LastName" type="text" placeholder="Last name" required [ngClass]="{'isValid': error }"></div>
          </div>

          <div class="row mb-20">
            <div class="col-12"><input formControlName="Email" type="email" placeholder="Email address" required [ngClass]="{'isValid': error }"></div>
          </div>
    
          <div class="row mb-20">
            <div class="col-12 col-sm-6 pr-md-2">
              <input formControlName="Password" type="password" placeholder="Password" required [ngClass]="{'isValid': error }">
              <div class="error-msg" *ngIf="!passwordValid">
                <p>Please ensure your password is: 6 characters minimum and <br>includes at least one number.</p>
              </div>
            </div>
            <div class="col-12 col-sm-6 pl-md-2">
              <input formControlName="ConfirmPassword" type="password" placeholder="Confirm password" required [ngClass]="{'isValid': error }">
              <div class="error-msg" *ngIf="!passwordsMatch">Passwords do not match.</div>
            </div>
          </div>
  
          <div class="row d-none">
            <input type="text" formControlName="Source">
            <input type="text" formControlName="ReferredBy">
            <input type="text" formControlName="JobId">
          </div>
    
          <hr>
    
          <div class="row mb-20">
            <div class="col-12 mb-3">
              <div class="custom-control custom-checkbox small-checkbox" [ngClass]="{'isValid': error }">
                <input class="custom-control-input" id="checkboxConsent" type="checkbox" formControlName="Consent" required>
                <label class="custom-control-label" for="checkboxConsent">I agree to Opogo’s <a href="{{opogoService.termsUrl}}" target="_blank">terms of service</a> and <a href="{{opogoService.privacyUrl}}" target="_blank">privacy policy</a> and to be contacted by email/text about my registration</label>
              </div>
            </div>
            <div class="col-12">
              <div class="custom-control custom-checkbox small-checkbox">
                <input class="custom-control-input" id="checkboxMarketing" type="checkbox" formControlName="Marketing">
                <label class="custom-control-label" for="checkboxMarketing">I’d like to receive product updates, offers and teaching resources from Opogo</label>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12 text-center mb-5 mb-md-0">
              <p class="error text-center mb-0" *ngIf="errorMsg"><strong>{{ errorMsg }}</strong></p>
              <button type="submit" class="btn btn-primary"> {{ jobId ? 'Apply for job' : 'Create account' }}</button>
              <p>Are you a school? <a href="{{opogoService.schoolSignUpUrl}}" target="_top">Sign up here</a></p>
            </div>
          </div>
    
        </div>
      </div>
    </form>
  </div>

   <!-- new Page -->
  <div class="container step great-work lets-do-it new-page" *ngIf="signedUp && !loggedIn && !signIn">
    <div class="text-center margin-block">
      <h2>Welcome to Opogo. <br>
        Please check your email to verify your account</h2> 
        
        <p class="pt-1 pb-2 sent-email">We sent an email to <b>{{userEmail}}</b> titled <br> 'Confirm your email address'. Click the link in the email to verify your account and access Opogo.</p>
       
       <img src="../../../../assets/img/mail.png" alt="">
    </div>
  </div>
    
  <!-- Great work / Congratulations -->
  <div class="container-fluid step great-work h-100 px-0" *ngIf="loggedIn && !signIn">
    <div class="row justify-content-center h-100">
      <div class="col-12">
    
        <div class="container" *ngIf="signedUp">
          <div class="row justify-content-center h-100">
            <div class="col-12 col-sm-9 col-md-8 text-center">
              <h2>Congratulations!</h2>
              <p>Your account is set up</p>
            </div>
          </div>
        </div>

        <div class="inner-container gray--bg">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-md-7 col-lg-6 mt-3">
              <h2>Download the Opogo app and get:</h2>
              <ul class="pl-0">
                <li>Daily tips and advice from education experts in The Hub</li>
                <li>FREE webinars & CPD events</li>
                <li>Career support on the phone, in-app or face-to-face</li>
                <li>Unique codes for our Ambassador Programme</li>
              </ul>
              <h6>Download our app</h6>
              <hr>
              <div class="row store-buttons align-items-center">
                <div class="col-12 col-sm-auto pr-2 mb-3 mb-sm-0">
                  <a class="app-store" href="{{appStoreUrl}}" target="_blank"></a>
                </div>
                <div class="col-12 col-sm-auto px-sm-2">
                  <a class="google-play" href="{{playStoreUrl}}" target="_blank"></a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 phones justify-content-center alig-self-end align-items-end d-flex p-md-0 mb-5 mb-md-0">
              <img class="img-fluid" src="../../../assets/img/phones-new-x3.png" alt="Opogo app screens">
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>  

  <!-- sign in form -->
  <div class="container sign-in" *ngIf="signIn">
    <div class="row justify-content-center">
      <div class="col-12">

        <div class="row mb-4 pb-1">
          <div class="col-12 text-center">
            <h2>Sign in to Opogo</h2>
          </div>
        </div>
  
        <form [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate class="loginForm">
          <div class="row mb-20">        
            <div class="form-group col-12">
              <input #opogousr type="email" formControlName="opogousr" placeholder="Enter your email address" required>
              <div *ngIf="!loginForm.get('opogousr').valid && loginForm.get('opogousr').touched && !loginForm.get('opogousr').pristine" class="error-msg">
                Please enter a valid email address.
              </div>
            </div>
            <div class="form-group col-12">
              <input #opogopsw type="password" formControlName="opogopsw" placeholder="Enter your password" required>
              <div *ngIf="!loginForm.get('opogopsw').valid && loginForm.get('opogopsw').touched && !loginForm.get('opogopsw').pristine" class="error-msg">
                Incorrect password.
              </div>
            </div>
                        
            <div class="col-12">
              <p class="text-center my-0 error" *ngIf="error && !loginForm.valid"><strong>Please fill in the fields highlighted in red above</strong></p>
              <div *ngIf="serverError"  class="text-center error-msg">{{serverError}}</div>
              <button class="btn btn-primary mb-3" type="submit">{{ jobId ? 'Apply for job' : 'Sign in'}}</button>
              <p class="text-center pt-3">Back to <a (click)="toggleSignIn()">Sign up</a>, or <a [routerLink]="['/forgotten-password']">forgotten your password?</a></p>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>