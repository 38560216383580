<div class="container" *ngIf="!passwordSet">
  <div class="row mt-5">
    <div class="col-12 text-center pt-2">
      <h4 class="mb-3">Welcome to Opogo</h4>
      <p class="mb-0">Hello Gavin King. St Laurence School has set you on the Opogo platform. <br>
        To get started, please set your password below.</p>
    </div>
  </div>

  <div class="row text-center change-password-block sign-in-block justify-content-center">
    <div class="left">
      
      <div class="row">
        <div class="col-12">
          <div class="box-header">Set your password</div>
        </div>
        <div class="col-12">
          <div class="box-body">
  
            <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="changePasswordForm">
              <p>Please ensure your password is, 8 characters minimum, <br> includes at least one uppercase character, and one number.</p>
              <div class="form-group mb-3">
                <input #newPassword type="password" formControlName="newPassword" placeholder="Set password" required>
                <div class="error-msg" *ngIf="changePasswordForm.get('newPassword').value === '' && changePasswordForm.get('newPassword').touched">Password is required</div>
                <div class="error-msg" *ngIf="changePasswordForm.get('newPassword').hasError('passwordStrength') && changePasswordForm.get('newPassword').touched">
                    <p>Please ensure your password is, 8 characters minimum, <br> includes at least one uppercase character, and one number.</p>
                </div>
              </div>
              <div class="form-group">
                <input type="password" formControlName="confirmPassword" placeholder="Confirm password" required>
                <div class="error-msg" *ngIf="changePasswordForm.get('confirmPassword').touched && (changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmPassword').value)">Passwords do not match.</div>
              </div>
              <div *ngIf="serverError" class="error-msg">{{serverError}}</div>
              <button type="submit" class="btn btn-primary">Enter</button>
            </form>
  
          </div>
        </div>
      </div>
  
    </div>  
  </div>
</div>

<!-- <div class="container" *ngIf="passwordSet"> -->
<div class="container">
  <div class="row justify-content-center mt-5">
    <div class="col-12 col-sm-9 col-md-8 pt-1 px-md-0 text-center">
      <h4>Congratulations!<br>Your account is set up</h4>
      <p><strong>And don’t forget, if you haven’t downloaded the Opogo<br> app, you’re missing out. Once signed in you’ll get;</strong></p>
      <p>
        Smart job searches and school matching<br>
        Daily tips and advice from education experts in The Hub<br>
        FREE webinars & CPD events<br>
        Career support on the phone, in-app or face-to-face<br>
        Unique codes for our Ambassador Programme
      </p>
      <div class="row store-buttons justify-content-center align-items-center">
        <div class="col-auto px-2">
          <a class="app-store"></a>
        </div>
        <div class="col-auto px-2">
          <a class="google-play"></a>
        </div>
      </div>
    </div>
    <div class="col-12 phones justify-content-center alig-self-end" [ngClass]="{'fixedPhones': fixedPhones }">
      <img class="img-fluid" src="../../../assets/img/phones@3x.png" alt="Opogo app screens">
    </div>
  </div>

  <div class="row">
    <div class="col-12">

    </div>
  </div>
</div>