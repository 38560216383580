<div class='chatrooms'>
  <div *ngFor="let chatRoom of chatRooms"
       class="{{'row no-gutters py-2 chatroom' + (chatRoom.UnreadMessages > 0 ? ' new-messages' : '')}}"
       (click)="selectChatRoom(chatRoom)">
    <div class="col-auto chat-participant-image">
      <div *ngIf="isDefaultPicture(chatRoom.Participants[0].ProfileImageUrl)" class="square op-avatar"></div>
      <img *ngIf='!isDefaultPicture(chatRoom.Participants[0].ProfileImageUrl)' src="{{chatRoom.Participants[0].ProfileImageUrl}}">

    </div>
    <div class="col pl-2 chat-participant-details">
      <div class="participant-name">
        <span>{{chatRoom.Participants[0].ContactName }}</span>
        <span *ngIf="chatRoom.UnreadMessages > 0"> ({{chatRoom.UnreadMessages}})</span>
      </div>
      <div class="participant-role">{{chatRoom.Participants[0].Role}}</div>
    </div>
    <div class="col-2dot5 px-1 text-right last-message-date">{{chatRoom.LastMessageDate | lastMessageDate }}</div>
  </div>
</div>