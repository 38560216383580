import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from "../../../services";
import { PasswordStrengthValidator } from '../../../helpers';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  serverError: string;
  email: string;
  token: string;
  passwordSet: boolean;
  fixedPhones: boolean;
  @ViewChild("newPassword") newPasswordInput: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerHeight > 1088 ) {
      this.fixedPhones = true
    } else {
      this.fixedPhones = false
    }
  }

  constructor(private auth: AuthService, 
              private router: Router,
              private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.email = params['email'];
      this.token = params['token'];
      this.isTokenValid();
    });
    this.onResize();
  }

  ngOnInit() {
    this.changePasswordForm = new FormGroup({
      'newPassword': new FormControl('', {
        validators: [Validators.required, Validators.minLength(8), PasswordStrengthValidator],
        updateOn: 'blur'
      }),
      'confirmPassword': new FormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      })
    },
    {
      updateOn: 'change'
    });

    // this.newPasswordInput.nativeElement.focus();
  }
  
  onSubmit(): void {
    if (this.changePasswordForm.valid) {
      this.auth.changePassword(this.email, this.changePasswordForm.get("newPassword").value, this.token).subscribe(
        result => {
          // this.router.navigate(['/resetpasswordsuccessful']);
          this.passwordSet = true;
        }, 
        error => { 
          this.serverError = error.json().error_description;
        }
      );
    }
  }

  isTokenValid() {
    this.auth.tokenValid(this.email, this.token).subscribe(
      result => {
        if (result._body === "false") {
          this.router.navigate(['/forgot-password'], { queryParams: { tokenHasExpired: true } });
        }
      }, 
      error => { 
        this.serverError = error.json().error_description;
      }
    );
  }

}
