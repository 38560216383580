import { Candidate, Ticket, VacancyBookingTime, VacancyDayStatus } from '.';

export interface Timesheet {
  Id?: string;
  CandidatesCount: number;
  SignOffDate: Date;
  SignedOffCount: number;
  TimesheetItems: TimesheetItem[];
  TimesheetItemsCount: number;
  WeekStart: Date;
  TimesheetStatus: string;
  Status: TimesheetStatus;
  SignedOff: boolean;
  WorkedDays: number;
  FullDaysCount: number;
  HalfDaysCount: number;
  OvertimeHours: number;
  CurrencySymbol: string;
  TotalAmount: number;
  AllCandidatesReviewed: boolean;
}

export interface TimesheetItem {
  PlacedCandidateId: string;
  PlacedCandidate: string;
  PlacedCandidatePhotoImageUrl: string;
  VacancyDays: TimesheetDay[];
  Status: number;
  SignedOff: boolean;
  SignedOffBy: string;
  SignOffDate: Date;
  TotalAmount: number;
  HoursWorked: number;
  CurrencySymbol: string;
  JobRole: string;
  JobRoleId: string;
  Site: string;
  SiteId: string;
  JobTags: string[];
  CandidatePending?: boolean;
  FullDaysCount: number;
  HalfDaysCount: number; 
  Selected: boolean;
  ShowTooltip?: boolean;
  CandidateReviewed: boolean;
  Ticket: Ticket;
}

export interface TimesheetDay {
  ClientName: string;
  JobRole: string;
  JobTags: string[];
  Id: string;
  VacancyUid: string;
  Date: Date;
  BookingTimeType: VacancyBookingTime;
  StartTime: any;
  DayStatus: VacancyDayStatus;
  DailyRate: number;
  ExtraHours: number;
  ExtraHoursRate: number;
  CurrencySymbol: string;
}

export enum TimesheetStatus {
  InProgress = 0,
  WaitingForApproval = 1,
  SignedOff = 2,
  Invoiced = 3
}
