type parentGetter = () => GenericRole;


export class JobRoleTree extends Array<GenericRole> {
  readonly length: number;

  readonly [n: number]: GenericRole;

  private constructor(items?: Array<GenericRole>) {
    super(...items);
  }

  static create<T>(): JobRoleTree {
    return Object.create(JobRoleTree.prototype);
  }

  setItems(data: any) {
    data.forEach(role => {
      this.push(new GenericRole().deserialize(role));
    });
  }

  findById(id: string) {
    if (!id) {
      return null;
    }
    return this.findChild(id);
  }

  findChild(id: string, roles: JobRoleTree = this): GenericRole {
    let res: GenericRole = null;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].Id === id) {
        res = roles[i];
        break;
      } else if (roles[i].JobRoles.length && !res) {
        res = this.findChild(id, roles[i].JobRoles);
      }
    }
    return res;
  }

  getChild(id: string): GenericRole {
    if (!id) {
      return null;
    }
    const child = this.find(item => item.Id === id);
    return child ? child : null;
  }
}


export class GenericRole {
  Id: string = null;
  Name: string = null;
  RoleId: string = null;
  JobRoles: JobRoleTree = JobRoleTree.create();
  SelectedRole: GenericRole = null;
  IsQuickSelect: boolean = false;
  expanded = false;

  deserialize(input: any, parent?: parentGetter) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(this)
      .forEach(key => {
        if (key === 'JobRoles') {
          input[key].forEach(role => {
            this.JobRoles.push(new GenericRole().deserialize(role, () => {
                return this;
              })
            );
          });
        } else {
          this[key] = input[key];
        }
      });
    if (parent) {
      this._parent = parent;
    }
    return this;
  }

  public addJobRoles(input: any, parent?: parentGetter) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    input.forEach(role => {
      this.JobRoles.push(new GenericRole().deserialize(role, () => {
          return this;
        })
      );
    });

    if (parent) {
      this._parent = parent;
    }
    return this;
  }


  get hasParent() {
    return !!this.parent;
  }

  get hasChildren() {
    return !!this.JobRoles.length;
  }

  get parent(): GenericRole {
    return this._parent();
  }

  private _parent() {
    return null;
  }

  clearSelected(fromRoot: boolean = false) {
    this.expanded = false;
    if (!fromRoot) {
      let parent: GenericRole = this.parent;
      while (parent) {
        parent.expanded = false;
        parent.SelectedRole = null;
        parent = parent.parent;
      }
    } else {
      const root = this.root;
      let selected = root.SelectedRole;
      root.SelectedRole = null;
      root.expanded = false;
      while (selected) {
        const temp = selected.SelectedRole;
        selected.SelectedRole = null;
        selected.expanded = false;
        selected = temp;
      }
    }

  }

  setSelected(input: GenericRole) {
    this.SelectedRole = input;
    this.expanded = true;
    const parent = this.parent;
    if (parent) {
      parent.setSelected(this);
    }
  }

  get root() {
    let parent = this.parent ? this.parent : this;
    while (true) {
      if (parent.hasParent) {
        parent = parent.parent;
      } else {
        break;
      }
    }
    return parent;
  }
}
