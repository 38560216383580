<div class='expandable-list-item' [ngClass]="{ 'highlighted': highlighted === true }">
  <div class="{{'expandable-list-item-header row no-gutters justify-content-between py-2 px-3' + (expandable ? ' expandable' : '') + (expanded ? ' expanded' : '') + (showWarning ? ' warning' : '') + (showSuccess ? ' success' : '')}}" 
              (click)="expandable && toggleItem()">
    <div class='{{"col-md-1half order-0" + (expanded ? " align-self-start col-11" : " align-self-center col-8")}}'>
      <ng-content select=".list-item-title"></ng-content>
    </div>
    <div class='col-8 col-md px-md-2 align-self-center order-3 order-md-1'>
      <ng-content select=".list-item-subtitle" *ngIf="!expandedSubtitle || (!expanded && expandedSubtitle)"></ng-content>
      <ng-content select=".list-item-subtitle-expanded" *ngIf="expanded"></ng-content>
    </div>
    <div class="{{'col-3 col-md-3half px-md-2 text-right align-self-center order-1 order-md-2' + (expanded ? ' d-none' : '')}}">
      <ng-content select=".list-item-summary"></ng-content>
    </div>
    <div class="{{'col-3 col-md-1 text-right pr-5 pr-md-0 align-self-center order-4 order-md-3'  + (expanded ? ' invisible' : '') + (showImage ? '' : ' d-none')}}">
      <ng-content select=".list-item-image"></ng-content>
    </div>    
    <div *ngIf="expanded && expandedImage && !showImage" class="col-3 col-md-1 text-right align-self-center order-4 order-md-3">
      <ng-content select=".list-item-image-expanded"></ng-content>
    </div>      
    <div class="col-auto text-right expand-icon align-self-center pl-md-2 order-2 order-md-4" *ngIf="expandable">
      <span *ngIf="!expanded" class="material-icons align-middle" [attr.data-au]="'expand-job-list-item'">expand_more</span>
      <span *ngIf="expanded" class="material-icons align-middle" [attr.data-au]="'collapse-job-list-item'">expand_less</span>
    </div>    
  </div>
  <div class="{{'px-3 bg-white' + (expanded ? '' : ' d-none')}}">
    <ng-content select=".list-item-details"></ng-content>
  </div>
</div>
