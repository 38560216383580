import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FEATURE_FLAG } from '@app/interfaces';
import { TalentPoolService } from '@app/services';
import { MemberTagCategory, TalentPoolCandidateDetailed } from '@app/models';
import { environment } from 'environments/environment';
import { Label } from '@app/localization/providers/localized-text.provider';

export enum CandidateTab {
  About = 0,
  Courses = 1,
  Reviews = 2
}

@Component({
  selector: 'app-candidate-details-modal',
  templateUrl: './candidate-details-modal.component.html',
  styleUrls: ['./candidate-details-modal.component.scss']
})
export class CandidateDetailsModalComponent implements OnInit {
  @Input() candidateId: string;
  @Input() recommended: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  candidate: TalentPoolCandidateDetailed;
  showLoader: boolean = false;
  selectedTab: CandidateTab = CandidateTab.About;
  CandidateTab = CandidateTab;
  primaryProfile: any;
  showAdditionalProfiles: boolean = true;
  qualificationsCategoryId = environment.tagCategories.qualificationsCategoryId;

  // Can't find a better way to do this with css grid
  // without a ton of shady css
  leftColumnTagCategories: MemberTagCategory[] = [];
  rightColumnTagCategories: MemberTagCategory[] = [];

  private readonly LocalizedLabel = Label;
  
  get FEATURE_FLAG() {
    return FEATURE_FLAG;
  }

  get TagCategories() {
    return this.candidate.TagCategories.sort((a,b) => a.OrderNo - b.OrderNo);
  }

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private talentService: TalentPoolService) { }

  ngOnInit() {
    this.showLoader = true;
    this.getCandidateDetails(this.candidateId).subscribe(
      result => {
        this.candidate = result;
        this.getPrimaryProfile();

        // really hate to be doing this but I don't see any better way of doing it
        // since we have to mix non-tags with actual tags
        if (!this.candidate.TagCategories?.some(tc => tc.Id === this.qualificationsCategoryId) && this.candidate.Courses.length > 0) {
          let qualificationsCategory = new MemberTagCategory();
          qualificationsCategory.Id = this.qualificationsCategoryId;
          qualificationsCategory.Description = 'Has qualifications';
          qualificationsCategory.OrderNo = 5;
          this.candidate.TagCategories = [...this.candidate.TagCategories||[], ...[qualificationsCategory]];
        }

        this.divideTagCategoriesIntoLeftAndRightColumns();

        this.showLoader = false;
      },
      error => {
        this.showLoader = false;
        console.log('getCandidateDetails error:', error);
      }
    );    
  }

  getCandidateDetails(candidateId): any {
    return this.talentService.getCandidateDetails(
      candidateId, true, true, false, null, null, true, true, true);
  }

  close() {
    this.onClose.emit();
  }
  
  setTab(tab: CandidateTab) {
    this.selectedTab = tab;
  }

  getPrimaryProfile() {
    for (const profile of this.candidate.Profiles) {
      if (profile.IsPrimary) {
        this.primaryProfile = profile;
      }
    }

    if (!this.primaryProfile) {
      this.primaryProfile = this.candidate.Profiles[0];
      this.primaryProfile.IsPrimary = true;
    }
  }

  toggleAdditionalProfilesVisibility() {
    this.showAdditionalProfiles = !this.showAdditionalProfiles;
  }

  goToTalentProfile() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['./talent/profile'], {
      queryParams: { id: this.candidateId, type: 'poolTalent' }
    }));
    window.open(url, '_blank');
  }

  getScoreStyle(score: number) {
    let style = "--rating: ";
    style += score ? score : '0';
    style += ";";
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  divideTagCategoriesIntoLeftAndRightColumns() {
    let _this = this;
    this.candidate.TagCategories?.forEach(function(tc, idx) {
      if (idx % 2 === 0) _this.leftColumnTagCategories.push(tc);
      else _this.rightColumnTagCategories.push(tc);
    })
  }

}
