import * as _ from 'underscore';

export interface Serializable<T> {
  serialize(): Object;
  deserialize(input: Object): T;
}

export abstract class BaseModel implements Serializable<BaseModel> {
  public Id: string | number = null;

  static createInstance<T extends BaseModel>(clazz: {new(): T}, data: any): T {
    return (new clazz()).deserialize(data);
  }

  preSerialize(): Object {
    const obj = {};

    Object.keys(this).forEach((value, key) => {
      obj[key] = value;
    });

    return obj;
  }

  getKeys() {
    let result: string[] = [];
    let currentObj = this;
    while (currentObj instanceof BaseModel) {
      result = [...Object.keys(currentObj), ...result];
      currentObj = Object.getPrototypeOf(currentObj);
    }

    return result;
  }

  serialize(): Object {
    const result = _.clone(this);

    this.getKeys().forEach((key: string) => {
      const propName = key.slice(1);

      if (key[0] === '_' && this.getterExists(propName)) {
        delete result[key];
        result[propName] = this[propName];
      }
    });

    return result;
  }

  getterExists(key: string): boolean {
    let isFound = false;
    let currentObject = this;
    while (currentObject instanceof BaseModel) {
      const descProto = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(currentObject), key);
      if (descProto && descProto.get) {
        isFound = true;
      }
      currentObject = Object.getPrototypeOf(currentObject);
    }

    return isFound;
  }

  isPropertyWritable(key: string): boolean {
    let isFound = false;
    let currentObject = this;
    while (currentObject instanceof BaseModel) {
      const desc = Object.getOwnPropertyDescriptor(currentObject, key);
      const descProto = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(currentObject), key);
      if ((desc && desc.writable) || (descProto && descProto.set)) {
        isFound = true;
      }
      currentObject = Object.getPrototypeOf(currentObject);
    }

    return isFound;
  }

  deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (!this.isPropertyWritable(key)) {
          return ;
        }

        if (typeof input[key] !== 'undefined') {
          this[key] = input[key];
        }
      });
    return this;
  }
}
