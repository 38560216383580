import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { DurationUnitType } from 'dayjs/plugin/duration';

@Pipe({
  name: 'duration'
})
export class DayJsDurationPipe implements PipeTransform {

  transform(value: number, unit: DurationUnitType = 's', format: string = 'default'): any {
    if (!value) {
      return value;
    }
    let formatted;
    if (format === 'default') {
      let l_format = '';
      const hrs = Math.floor(value / 3600);
      const mins = Math.floor(value % 3600 / 60);
      if (hrs > 0) {
        l_format = 'H[hr]';
        if (mins > 0) {
          l_format = l_format.concat(' ');
        }
      }

      if (mins > 0) {
        l_format = l_format.concat('m[min]');
      }
      formatted = (<any>dayjs.duration(value, unit)).format(l_format, {
        usePlural: false
      });
    } else {
      formatted = (<any>dayjs.duration(value, unit)).format(format);
    }
    return formatted;
  }

}
