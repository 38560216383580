<div class="container-fluid tutorials-header px-0 px-md-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-5"><h2>Platform Tutorials</h2></div>
    </div>
  </div>
</div>

<div class="container-fluid tutorials-body px-0 px-md-0">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h3>How to build a job</h3>
        <div class="embed-container">
          <iframe src="https://player.vimeo.com/video/902928119?h=687c763499&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " 
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" 
            title="Build a job"></iframe>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h3>Book directly from your Talent Pool</h3>
        <div class="embed-container">
          <iframe src="https://player.vimeo.com/video/902931061?h=a135e4bc67&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " 
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" 
            title="Talent pool"></iframe>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h3>Using first to accept and multiple roles</h3>
        <div class="embed-container">
          <iframe src="https://player.vimeo.com/video/902929612?h=642e5a1cb1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " 
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          title="First to accept and multiple roles"></iframe>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h3>How to book candidates for a full or half term</h3>
        <div class="embed-container">
          <iframe src="https://player.vimeo.com/video/902934695?h=01fb425483&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 " 
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          title="Termly Contracts"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>