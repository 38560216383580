<div class="modal-header text-right">
  <div class='close-modal px-2' (click)="onCancel()">X</div>
</div>
<div class="modal-body text-center">
  <div class="user-profile-img op-avatar no-gutters">
    <img src="{{candidate.ProfileImageURL}}" alt="profile-img">
  </div>
  <div class="dialog-text my-4">
    <span>
      <p>Are you sure you want to remove</p>
      <p class="member-name">{{candidate.DisplayName}}</p>
      <p>from your Talent Pool?</p>
    </span>
  </div>
  <div class="dialog-actions">
    <div class="dialog-buttons">
      <button class="px-2 btn btn-flat" (click)="onConfirm()">Yes</button>
      <button class="px-2 btn btn-light" (click)="onCancel()">No</button>
    </div>
  </div>
</div>