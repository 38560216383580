import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppTitle } from '../interfaces/header';

@Injectable()
export class HeaderService {

  public headerChangeEvent: EventEmitter<any>;

  private headerObjectSource = new BehaviorSubject<AppTitle>({
    title: ''
  });

  headerObject = this.headerObjectSource.asObservable();

  constructor() {
    this.headerChangeEvent = new EventEmitter<any>();
  }

  changeHeader(header: AppTitle) {
    this.headerObjectSource.next(header);
  }

  headerChanged() {
    this.headerChangeEvent.emit();
  }
}
