import { Injectable } from "@angular/core";

export enum Label {
    Travel = 'Travel',
    CV = 'CV',
    SupplyLC = 'SupplyLC',
    SupplyUC = 'SupplyUC',
    SupplyJobLC = 'SupplyJobLC', 
    SupplyAvailability = 'SupplyAvailability',
    CandidateChat = 'CandidateChat',
    MemberLC = 'MemberLC',
    MembersLC = 'MembersLC',
    CandidateLC = 'CandidateLC',
    CandidateUC = 'CandidateUC',
    CandidatesLC = 'CandidatesLC',
    CandidatesUC = 'CandidatesUC',
    BankStaffIcon = 'BankStaffIcon',
    BankStaffTitle = 'BankStaffTitle',
    BankStaffBadge = 'BankStaffBadge',
    AllCandidatesGroupName = 'AllCandidatesGroupName',
    AllBankStaffGroupName = 'AllBankStaffGroupName'
}

export enum Text {
    BookMoreSupplyCover = 'BookMoreSupplyCover',
    LoadingAvailabilityDays = 'LoadingAvailabilityDays',
    CandidateReviewSubtitle = 'CandidateReviewSubtitle',
    WidgetRoleUnavailableTooltip = 'WidgetRoleUnavailableTooltip',
    BookingConfirmationBodyText = 'BookingConfirmationBodyText'
}

export enum Symbol {
    Currency = 'Currency'
}

type Labels = {
    [key in Label]: string;
}

type Texts = {
    [key in Text]: string;
}

const localizedLabels = <Labels>{
    Travel: $localize`Journey`,
    CV: $localize`View CV`,
    SupplyLC: $localize`supply`,
    SupplyUC: $localize`Supply`,
    SupplyJobLC: $localize`supply job`,
    SupplyAvailability: $localize`Supply availability`,
    CandidateChat: $localize`Candidate chat`,
    MemberLC: $localize`member`,
    MembersLC: $localize`members`,
    CandidateLC: $localize`candidate`,
    CandidateUC: $localize`Candidate`,
    CandidatesLC: $localize`candidates`,
    CandidatesUC: $localize`Candidates`,
    BankStaffIcon: $localize`B`,
    BankStaffTitle: $localize`:Title displayed on the bank staff tab:Bank Staff`,
    BankStaffBadge: $localize`:Badge displayed under candidates' names when booking supply:BANK STAFF`,
    AllCandidatesGroupName: $localize`All Candidates`,
    AllBankStaffGroupName: $localize`All Bank Staff`
}

const localizedTexts = <Texts>{ 
    BookMoreSupplyCover: $localize`Book more supply cover`,
    LoadingAvailabilityDays: $localize`Loading candidate's availability days`,
    CandidateReviewSubtitle:  $localize`:In this case, supply needs to be translated to SUB (singular), rather than SUBS, like everywhere else:Leaving a short review will help the candidate find future supply work`,
    WidgetRoleUnavailableTooltip: $localize`:Tooltip text displayed when selected role is unavailable for termly contracts:Candidate is not open for Termly Contract offers for selected job role`,
    BookingConfirmationBodyText: $localize`You will receive an alert when you get a candidate response. And rest assured,
    we'll be monitoring and chasing your candidates for a response in the background.`
}

const isLabel = (x: any): x is Label => Object.keys(localizedLabels).includes(x);
const isText = (x: any): x is Text => Object.keys(localizedTexts).includes(x);

Injectable()
export class LocalizedTextProvider {
    getLocalizedText(key: Label | Text) {
        return isLabel(key) ? localizedLabels[key] : localizedTexts[key];
    }
}

export const DefaultLocaleCurrencyCode: string = $localize`GBP`;