import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() color: string = '#20c4ff';
  @Input() disabledColor: string = '#20c4ff66';
  @Input() inactiveColor: string = '#eaedf3';
  @Input() disabled: boolean = false;
  @Input() on: boolean =  false;
  @Input() toggleId: string;
  @Output() onToggled: EventEmitter<any> = new EventEmitter<any>(); 

  labelColor: string = this.inactiveColor;

  eventEmitted: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.setToggleColor(this.on);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.eventEmitted && changes.on !== undefined) {
      this.eventEmitted = false;
      return;
    }
    if (changes.on !== undefined && changes.on.previousValue !== changes.on.currentValue) {
      this.setToggleColor(changes.on.currentValue);
    }

    if (changes.disabled !== undefined && changes.disabled.previousValue !== changes.disabled.currentValue) {
      this.setToggleColor(this.on);
    }
  }

  private setToggleColor(on: boolean) {
    let color = !this.disabled ? this.color : this.disabledColor;
    this.labelColor = on ? color : this.inactiveColor;
  }

  onToggle(event: Event) {
    this.setToggleColor(this.on);
    this.eventEmitted = true;
    this.onToggled.emit(this.on);
  }
}
