import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Ticket, TicketCreateModel } from '@app/interfaces/ticket';

@Injectable()
export class TicketService {

  constructor(private http: HttpService) { }

  createTicket(ticketModel: TicketCreateModel): Observable<Ticket> {
    let url: string = '/tickets/create';
    return this.http.post(url, ticketModel);
  }
}
