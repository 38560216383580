import { Directive, HostListener, ElementRef, Input } from "@angular/core";

@Directive({
	selector: "textarea[autoresize]" // Attribute selector
})
export class AutoresizeDirective {

    @HostListener('input', ['$event.target'])
	onInput(textArea: HTMLTextAreaElement): void {
		this.adjust();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.adjust();
    }

    @Input('ngModel') model: any;

    @Input('autoresize') maxHeight: number;
  
	constructor(public element: ElementRef) {}
  
	ngOnInit(): void {
		this.adjust();
    }
  
	adjust(): void {
        let textarea = this.element.nativeElement, newHeight;
            
        if (textarea) {
            if (textarea.value == "" && this.model && 
                !textarea.classList.contains("ng-dirty") && 
                !textarea.classList.contains("ng-touched") && 
                !textarea.classList.contains("ng-pristine")) {
                textarea.value = this.model;
            }

            textarea.style.overflow = "hidden";
            textarea.style.height = "auto";

            if (this.maxHeight) {
                newHeight = Math.min(textarea.scrollHeight, this.maxHeight);
            } else {
                newHeight = textarea.scrollHeight;
            }

            textarea.style.height = newHeight + "px";
        }
	}
}