<div class="text-center sign-block row" device>
  <div class="col-6 form-wrap">
    <div class="logo-container">
      <img src="assets/img/opogo_logo.png">
    </div>

    <form class="sign-form">
      <h4 class="form-head">Thank you</h4>
      <p class="help-text">Please check your email and follow the link to reset.</p>
    </form>

    <div class="outside-footer"></div>
  </div>

  <div class="col-6" *ngIf="!device.mobile">
    <app-outside-img-container></app-outside-img-container>
  </div>

</div>
