import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-month-picker-popup',
  templateUrl: './month-picker-popup.component.html',
  styleUrls: ['./month-picker-popup.component.scss']
})
export class MonthPickerPopupComponent implements OnInit {
  @Input() show: boolean;
  @Input() year: number;
  @Input() month: number;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();   
  currentYear: number;
  currentMonth: number;
  selectedYear: number;
  selectedMonth: number;
  firstYear: number = 2010;

  constructor() {
    let today = new Date();
    this.currentYear = today.getFullYear();
    this.currentMonth = today.getMonth();
   }

  ngOnInit() {
    this.selectedYear = this.year ? this.year : this.currentYear;
    this.selectedMonth = this.month ? this.month : this.currentMonth;
  }

  prevYear() {
    if (this.selectedYear > this.firstYear) {
      this.selectedYear--;
    }
  }

  nextYear() {
    if (this.selectedYear < this.currentYear) {
      this.selectedYear++;

      if (this.selectedYear === this.currentYear && this.selectedMonth > this.currentMonth) {
        this.selectedMonth = this.currentMonth;
      }
    }
  }

  setMonth(month : number) {
    if (this.selectedYear === this.currentYear && month > this.currentMonth) {
      return;
    }
    this.selectedMonth = month;
    this.onSelect.emit({ year: this.selectedYear, month: this.selectedMonth});
  }

}
