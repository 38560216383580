import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

@Component({
  selector: 'app-character-reference-questionnaire',
  templateUrl: './character-reference-questionnaire.component.html',
  styleUrls: ['./character-reference-questionnaire.component.scss']
})
export class CharacterReferenceQuestionnaireComponent implements OnInit {
  @Input() reference: any;
  @Input() disabled: boolean = false;

  @Input() submitLoader: boolean = false;
  @Input() onUpdateReference: Function;
  @Input() submissionError: boolean = false;
  
  isNotValid: boolean = false;

  textLength: number = 0;
  currentDate: Dayjs = dayjs()
  constructor() { }

  ngOnInit() {
  }

  updateReference() {
    this.validateControls();

    if (this.isNotValid) {
      return;
    }

    this.onUpdateReference();
  }

  validateControls() {
    this.isNotValid = false;
    if (!this.reference.AdditionalNotes) {
      this.isNotValid = true;
    }
  }

  countTextLength($e) {
    this.textLength = $e.currentTarget.value.length;
  }

  showConfirmationStatement(): boolean {
    if (!this.reference) {
      return false;
    }

    return this.disabled && this.reference.Status >= 3;
  }

}
