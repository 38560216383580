export enum PageSize {
  xxs = 5,
  xs = 10,
  s = 15,
  m = 20,
  mm = 30,
  md = 50,
  lg = 100
}

const pagination_start_index = 0;
const default_page_size = PageSize.xxs;

export class Pagination {
  private _max_items = 0;
  item_length = 0;
  to_swap = 0;
  private _page_size: PageSize = PageSize.xxs;
  pages: number[] = [1];
  private _page = pagination_start_index;


  constructor(page_size: string | number = PageSize.xxs, page: string | number = pagination_start_index) {
    this.page = page;
    this.page_size = page_size;
    this.buildPages();
  }

  // default parameters name, override on extend
  get paramKeys() {
    return ['page', 'page_size'];
  }

  public deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    this.paramKeys.forEach(key => {
      if (typeof input[key] !== 'undefined') {
        this[key] = input[key];
      } else {
        this[key] = null;
      }
    });
    return this;
  }

  // to implement serialization parameters for extend classes
  public serialize() {
    const temp = {};
    this.paramKeys.forEach(key => {
      if (this.exists(this[key])) {
        temp[key] = this[key];
      }
    });

    return temp;
  }

  set page(value: any) {
    if (!value || value === pagination_start_index) {
      this._page = pagination_start_index;
    } else {
      this._page = typeof value === 'string' ? parseInt(value, 10) : value;
    }
  }

  get page() {
    return this._page;
  }

  set page_size(value: any) {
    if (!value) {
      this.page_size = default_page_size;
    } else {
      this._page_size = typeof value === 'string' ? parseInt(value, 10) : value;
    }
    this.buildPages();
  }

  get page_size() {
    return this._page_size;
  }

  set max_items(value: number) {
    if (value) {
      this._max_items = value;
    } else {
      this._max_items = 0;
    }
    this.buildPages();
  }

  get max_items(): number {
    return this._max_items;
  }

  decrementMax(): number {
    this.max_items = this.max_items - 1;
    this.item_length = this.item_length - 1;
    this.page = Math.floor(this.item_length / this.page_size);
    this.to_swap = this.item_length % this.page_size;
    return this.max_items;
  }

  buildPages(): void {
    const pages_counter = Math.ceil(this.max_items / this.page_size);
    let i = 1;
    this.pages = [];
    do {
      this.pages.push(i);
      i++;
    } while (i <= pages_counter);
    if (this.page > this.last && pages_counter !== 0) {
      this.page = this.last;
    }
  }

  get hasPrevious(): boolean {
    return this.page > pagination_start_index;
  }

  get hasNext(): boolean {
    const modifier = pagination_start_index ? 0 : 1;
    return this.page + modifier < Math.ceil(this.max_items / this.page_size);
  }

  get previous(): number {
    return this.page - 1;
  }

  get next(): number {
    return this.page + 1;
  }

  get first(): number {
    return 1;
  }

  get last(): number {
    return this.pages[this.pages.length - 1];
  }

  reset(): void {
    this.page = pagination_start_index;
    this.page_size = default_page_size;
    this.max_items = 0;
    this.to_swap = 0;
    this.item_length = 0;
  }

  // to determine if any kind of query parameters is equal to current pagination parameters
  isEqual(input: any): boolean {
    if (typeof input === 'undefined' || input === null) {
      return false;
    }

    const tThis = this.serialize();

    this.paramKeys.forEach(key => {
      if (
        this.exists(tThis[key])
        && this.exists(input[key])
        && tThis[key].toString() !== input[key].toString()) {
        return false;
      } else if (this.exists(input[key]) && !this.exists(tThis[key])) {
        return false;
      }
    });
    return true;
  }

  private exists(input: any): boolean {
    return (input || input === false || input === 0);
  }
}
