import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  unsubscribing : boolean = false;
  checking: boolean = false;
  subscribed: boolean = false;
  public PushSubscriptionChangeEvent : EventEmitter<any>;

  constructor(
    private swPush: SwPush,
    private router: Router,
    private http: HttpService,
    private notificationsService: NotificationsService) 
  {
    this.PushSubscriptionChangeEvent = new EventEmitter<any>();
    this.arePushNotificationsTurnedOn();

    this.swPush.notificationClicks.subscribe( event => {
      if (event.notification.data && event.notification.data.type) {

        const notificationConfig = this.notificationsService.notificationsConfig.get(event.notification.data.type);
        if (notificationConfig) {
          this.router.navigate([notificationConfig.link]);          
        }

      }
    });

  }

  arePushNotififcationsDisabled(): boolean {
    return !this.swPush.isEnabled || !(window as any).Notification || (window as any).Notification.permission === "denied";
  }

  arePushNotificationsTurnedOn() {
    if (!(window as any).Notification || (window as any).Notification.permission !== "granted") {
      this.subscribed = false;
      this.PushSubscriptionChangeEvent.emit();
      return;
    }

    this.checking = true;

    const pushSubscription = this.swPush.subscription.subscribe( sub => {
      if (this.checking) {
        this.checking = false;
        if (sub === null) {
          this.subscribed = false;
          this.PushSubscriptionChangeEvent.emit();
          pushSubscription.unsubscribe();
        } else {
          this.checkSubscription(sub).subscribe( result => {
            this.subscribed = result;
            this.PushSubscriptionChangeEvent.emit();
            pushSubscription.unsubscribe();
          });
        }
      }
    });
  }

  subscribeToNotifications() {
     if (this.swPush.isEnabled === false) {
       console.log("Service worker is not supported or disabled.");
       return;
     }

    this.swPush.requestSubscription({
        serverPublicKey: environment.pushNotifications.vapidKey
    })
    .then(sub => {
      this.addPushSubscriber(sub). subscribe(
         result => this.arePushNotificationsTurnedOn()
      );
    })
    .catch(err => {
      console.error("Could not subscribe to notifications", err);
      this.arePushNotificationsTurnedOn();
    });
  }

  addPushSubscriber(sub : PushSubscription): Observable<boolean> {
    const url = `/account/pushsubscription`;
    const params = {
      Endpoint: sub.endpoint,
      P256dhKey: sub.toJSON().keys.p256dh,
      AuthKey: sub.toJSON().keys.auth
    };

    return this.http.post(url, params);
  }

  unsubscribe() {
    this.unsubscribing = true;
    const pushSubscription = this.swPush.subscription.subscribe( sub => {
      if (this.unsubscribing) {

        this.unsubscribing = false; 
        if (sub === null) {
          return;
        }       

        this.swPush.unsubscribe()
        .then( () => {
          this.removeSubscriber(sub).subscribe(
            result => {
              this.arePushNotificationsTurnedOn();
              pushSubscription.unsubscribe();
            }
          );
        })
        .catch(err => {
          console.error("Could not unsubscribe from push notifications", err);
          pushSubscription.unsubscribe();
        })

      }
    });
  }

  removeSubscriber(sub: PushSubscription): Observable<boolean> {
    const url =  `/account/pushsubscription`;
    const options = {
      body: JSON.stringify(sub.endpoint)
    };

    return this.http.request('DELETE', url, options);    
  }

  checkSubscription(sub: PushSubscription): Observable<boolean> {
    const url =  `/account/pushsubscription/check`;
    return this.http.post(url, JSON.stringify(sub.endpoint));   
  }

}
