import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autoplay'
})
export class AutoplayPipe implements PipeTransform {
  transform(videoUrl: any, timeInSeconds?: any): any {
    if (videoUrl) {
      if (videoUrl.indexOf('autoplay=1') === -1) {
        if (videoUrl.indexOf('?') === -1) {
          videoUrl += '?';
        } else {
          videoUrl += '&';
        }
        videoUrl += 'autoplay=1';
      }
      if (timeInSeconds) {
        let minutes = Math.floor(timeInSeconds / 60);
        let seconds = timeInSeconds - (minutes * 60);
        let time = minutes > 0 ? minutes > 60 ? `${Math.floor(minutes / 60)}h${minutes % 60}m${seconds}s` : `${minutes}m${seconds}s` : `${seconds}s`;
        if (videoUrl.indexOf('vimeo.com') !== -1) {
          videoUrl += '#';
        } else {
          videoUrl += '&';
        }
        videoUrl += `t=${time}`;
      }
    }
    return videoUrl;
  }
}
