<div #progress class="progress mx-auto">
  <span class="progress-left">
      <span #left class="progress-bar"></span>
  </span>
  <span class="progress-right">
      <span #right class="progress-bar"></span>
  </span>
  <div #content class="rogress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
    <div class="font-weight-bold text-center px-3">{{text}}</div>
  </div>
</div>
