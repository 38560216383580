import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfig } from '../modal';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig) { }

  
  ngOnInit() {
    this.updatePosition();
    
    if (this.data.HighlightElement) {
      this.dialogRef.afterOpened().subscribe(() => {
        this.data.HighlightElement.nativeElement.classList.add('highlighted')
      });
  
      this.dialogRef.beforeClosed().subscribe(()=> {
        this.data.HighlightElement.nativeElement.classList.remove('highlighted')
      })
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updatePosition();
  }
  
  updatePosition() {
    const matDialogConfig = new MatDialogConfig()
    const rect: DOMRect = this.data.HighlightElement.nativeElement.getBoundingClientRect()
    
    let modalPosition = {right: window.innerWidth - (rect.right + 15) + 'px'};

    if (this.data.PositionRelativeToElement === 'Bottom') {
      modalPosition['top'] = rect.bottom + 5 + 'px';
    } else if (this.data.PositionRelativeToElement === 'Top') {
      modalPosition['top'] = rect.top - (this.data.Height + 25) + 'px';
    }

    matDialogConfig.position = modalPosition;
    this.dialogRef.updatePosition(matDialogConfig.position)
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
