import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RouterEvent } from '@angular/router';
import { MenuService } from './services/menu.service';
import { DeviceService } from '@services/device.service';
import { filter, map } from 'rxjs/operators';
import { AuthService, ClientService, HeaderService } from '@services';
import { VersionCheckService } from './services/version-check.service';

// noinspection TypeScriptUnresolvedFunction
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCourses: boolean;
  hideShell = false;
  oldVersion = {version: '1.2.10'};
  newVersion;
  config;

  constructor(
      private auth: AuthService,
      private route: ActivatedRoute,
      private client: ClientService,
      public router: Router,
      public menuService: MenuService,
      public headerService: HeaderService,
      public device: DeviceService,
      private versionCheckService: VersionCheckService
  ) {
    setInterval(() => {
      this.versionCheckService.getUpdate()
          .subscribe((data) => {
            this.newVersion = data;
            this.newVersion = this.newVersion.version;

            if (this.oldVersion.version !== this.newVersion && this.newVersion !== localStorage.getItem('version')) {
              this.oldVersion.version = this.newVersion;
              localStorage.setItem('version', this.newVersion);
              
              if (!this.hideShell) {
                this.auth.ThirdPartyServicesAuthenticated = false;
              }
              
              location.reload();
            }
          });
    }, 15000);

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let child = this.route;
        while (child.firstChild) {
          child = child.firstChild;
        }
        this.isCourses = !!child.snapshot.data.isCourses;
        this.hideShell = !!child.snapshot.data.hideShell;

        if (this.auth.isLoggedIn() && !this.hideShell && !this.auth.ThirdPartyServicesAuthenticated) {
          this.AuthenticateThirdPartyServices();
        }
      }
    });
  }

  AuthenticateThirdPartyServices() {
    this.client.getClientSummary().subscribe(data => {

      // Commented out because of the moe_webSdk.min.latest.js making endless requests to https://sdk-02.moengage.com/websdksettings?{app_id}
      // if (data.Email) {
      //   this.auth.tryLogoutMoEngage();
  
      //   try {
      //     (window as any).Moengage.add_unique_user_id(data.Email);
      //   }
      //   catch(e) {}
      // }
  
      if (data.MemberUserId) {
        try {
          (window as any).smartlook('identify', data.MemberUserId, {
            "schoolName": data.ClientName
          })
        }
        catch(e) {}
      }
  
      this.auth.ThirdPartyServicesAuthenticated = true;
    });
  }
}
