import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { AuthService, RegistrationService } from '@app/modules/_shared';
import { ChatChannel, ChatRoom } from '@app/interfaces';
import { tap } from 'rxjs/operators';
import * as Pubnub from 'pubnub';

export interface SelectedChatRoom {
  ChatRoom: ChatRoom;
  Messages: Pubnub.MessageEvent[];
  Expanded: boolean;
}

@Injectable()
export class ChatService {
  public NewMessageEvent: EventEmitter<any>;
  public NewChatRoomSelected: EventEmitter<any>;
  public NewChatRoomCreated: EventEmitter<any>;
  public ChatRoomExpanded: EventEmitter<any>;
  public ChatRoomsRefreshed: EventEmitter<any>;
  public ShowChatPopup: EventEmitter<any>;
  public ChatRooms: ChatRoom[] = [];
  public SelectedChatRooms: SelectedChatRoom[] = [];

  allChatRooms: any = [];
  subscribedChannels: any = [];
  unreadMessages: number = 0;
  selectedChatRoomsSize: number;
  expandedTabs: number = 0;
  selectedTabIndex: number = 0;

  logInEventSubscription: any;
  logOutEventSubscription: any;
  pubnub: Pubnub;

  constructor(
    private http: HttpService,
    private registrationService: RegistrationService,
    private auth: AuthService
  ) {

    this.NewMessageEvent = new EventEmitter<any>();
    this.NewChatRoomSelected = new EventEmitter<any>();
    this.NewChatRoomCreated = new EventEmitter<any>();
    this.ChatRoomExpanded = new EventEmitter<any>();
    this.ShowChatPopup = new EventEmitter<any>();
    this.ChatRoomsRefreshed = new EventEmitter<any>();

    this.auth.LogInEvent.subscribe(() => {
      this.initializePubNub();
    });

    this.setSelectedChatRoomsSize();

    window.addEventListener('resize', (event) => {
      this.setSelectedChatRoomsSize();
    });
  }


  setSelectedChatRoomsSize() {
    if (window.innerWidth > 1361) {
      this.selectedChatRoomsSize = this.expandedTabs === 0 ? 3 : 2;
    }

    if (window.innerWidth <= 1361 && window.innerWidth > 1345) {
        this.selectedChatRoomsSize = 2;
    }

    if (window.innerWidth <= 1345 && window.innerWidth > 1180 ) {
      this.selectedChatRoomsSize = this.expandedTabs <= 1 ? 2 : 1;
    }

    if (window.innerWidth <= 1080 && window.innerWidth > 1015) {
      this.selectedChatRoomsSize = this.expandedTabs === 0 ? 2 : 1;
    }

    if (window.innerWidth <= 1015) {
      this.selectedChatRoomsSize = 1;
    }
    
  }

  initializePubNub(): Observable<any> {
    return new Observable(subscriber => {
      this.registrationService.getLoggedInUserDetails().subscribe(
        result => {
          this.pubnub = new Pubnub({
            userId: result.MemberId,
            subscribeKey: environment.pubNub.subscribeKey,
            publishKey: environment.pubNub.publishKey
          });

          this.pubnub.addListener({
            status: (statusEvent) => {
              if (statusEvent.category === 'PNConnectedCategory') {
                //alert('statusChanged');
              }
            },
            message: (message) => {
              if (message != undefined && message.message != undefined) {
                this.updateSelectedChatRoomMessages(message);
                this.NewMessageEvent.emit(message.message);
              }
            },
            presence: (presenceEvent) => {
              //alert('presenceEvent');
            },
            messageAction: (messageAction) => {
            }
          });
      
          this.subscribeAllChannels();
      
          this.logInEventSubscription = this.auth.LogInEvent.subscribe(() => {
            this.subscribeAllChannels();
          });
      
          this.logOutEventSubscription = this.auth.LogOutEvent.subscribe(() => {
            this.unsubscribeAllChannels();
          });
          subscriber.next();
        },
        error => {
          console.log(`PubNub initialization failed, unable to fetch user details from the server. Error: ${error}`)
        }
      )
    })
  }

  private updateSelectedChatRoomMessages(message: Pubnub.MessageEvent) {
    this.SelectedChatRooms.forEach ( r => {
      if (r.ChatRoom.ChannelId === message.message.pn_other.chatChannel) {
        r.Messages = [...r.Messages, message];
        this.resetUnreadMessages(message.message.pn_other.chatChannel).subscribe();
        if(this.getMessageOwner(message.message) != r.ChatRoom.Participants[1].ContactId){
          this.publishReadReceipt(message.message.pn_other.chatChannel, message.timetoken);
        }
      }
    });
  }

  getAllChatRooms(): Observable<any> {
    let url: string = `/chat/allchatrooms`;
    return this.http.get(url);
  }

  addChatRoom(candidateId: string): Observable<ChatChannel> {
    let url: string = `/chat/add?candidateId=${candidateId}`;
    return this.http.post(url, null).pipe(tap(
       () => this.NewChatRoomCreated.emit()
    ));
  }

  addConsultantChatRoom(consultantId: string): Observable<ChatChannel> {
    let url: string = `/chat/addconsultantchannel?consultantId=${consultantId}`;
    return this.http.post(url, null).pipe(tap(
       () => this.NewChatRoomCreated.emit()
    ));
  }

  addUnreadMessage(channelId, message): Observable<any> {
    let url: string = `/chat/addunreadmessage?channelId=${channelId}&message=${message}`;
    return this.http.post(url, null);
  }

  resetUnreadMessages(channelId): Observable<any> {
    let url: string = `/chat/resetunreadmessages?channelId=${channelId}`;
    return this.http.get(url);
  }

  subscribeChannel(channelId) {
    if (this.subscribedChannels.indexOf(channelId) === -1) {

      this.pubnub.subscribe({
        channels: [`${channelId}`]
      });

      this.subscribedChannels.push(channelId);
    }
  }

  unsubscribeAllChannels() {
    this.pubnub.unsubscribeAll();
    this.subscribedChannels = [];
  }

  subscribeAllChannels() {
    this.allChatRooms = [];
    this.getAllChatRooms().subscribe(
      result => {
        if (result) {
          this.allChatRooms = [...result];
          for (let chatRoom of this.allChatRooms) {
            this.subscribeChannel(chatRoom.ChannelId);
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getMessages(channel, start, callback) {
    this.pubnub.fetchMessages(
      {
        channels: [channel],
        start: start // start time token to fetch
      },
      callback
    );
  }

  publishMessage(channelId, message) {
    message.messageId = this.pubnub.getUUID();

    var publishConfig = {
      channel: channelId,
      message: {
        pn_gcm: {
          data: {
            message: message
          }
        },
        pn_apns: {
          aps: message
        },
        pn_other: message
      }
    };

    this.pubnub.publish(publishConfig,
      (status, response) => {
        console.log(status, response);
      }
    );
  }

  publishReadReceipt(channelId, timetoken){
    this.pubnub.addMessageAction({
      channel: channelId,
      messageTimetoken: timetoken,
      action: {
          type: 'receipt',
          value: 'read',
      },
    });
  }

  getMessageOwner(message){
    return message.pn_other.userId;
  }

  selectChatRoom(chatRoom : ChatRoom) {
    if (this.SelectedChatRooms.find(r => r.ChatRoom.ChannelId === chatRoom.ChannelId)) {
        return;
    };

    let newSelectedChatRoom: SelectedChatRoom = {
      ChatRoom: chatRoom,
      Messages: [],
      Expanded: false
    }

    this.SelectedChatRooms = [...this.SelectedChatRooms, newSelectedChatRoom];

    if (this.SelectedChatRooms.length > this.selectedChatRoomsSize) {
      if (this.SelectedChatRooms[0].Expanded) {
        this.expandedTabs--;
        this.setSelectedChatRoomsSize();
      }
      this.SelectedChatRooms = this.SelectedChatRooms.slice(1);
    }

    let sendReadReceipt = false;
    if (chatRoom.UnreadMessages > 0) {
      this.resetUnreadMessages(chatRoom.ChannelId).subscribe();
      sendReadReceipt = true;
    }

    this.getMessages(chatRoom.ChannelId, null,
      (status, response) => { 
        if (response.channels[chatRoom.ChannelId] !== undefined && response.channels[chatRoom.ChannelId].length > 0) {
          newSelectedChatRoom.Messages = response.channels[chatRoom.ChannelId];
          this.NewChatRoomSelected.emit(chatRoom);
          if(sendReadReceipt){
            const [lastMessage] = response.channels[chatRoom.ChannelId].slice(-1);
            this.publishReadReceipt(chatRoom.ChannelId, lastMessage.timetoken);
          }
        }
      }
    )

  }

  selectChatRoomByContactId(contactId: string) {
    let chatRoom = this.ChatRooms.find(r => r.Participants[0].ContactId === contactId);
    this.selectChatRoom(chatRoom);
  }

  selectChatRoomByChannelId(channelId: string) {
    let chatRoom = this.ChatRooms.find(r => r.ChannelId === channelId);
    this.selectChatRoom(chatRoom);
  }

  tryGetChatRoomByContactUserId(contactUserId: string) {
    let chatroom = this.ChatRooms.find(r => r.Participants[0].ContactUserId === contactUserId);
    return chatroom;
  }

  expandChatRoom(index: number) {
    this.SelectedChatRooms[index].Expanded = !this.SelectedChatRooms[index].Expanded;

    if (!this.SelectedChatRooms[index].Expanded) {
      this.expandedTabs--;
    } else{
      this.expandedTabs++;
    }

    this.setSelectedChatRoomsSize();

    if (this.SelectedChatRooms.length <= this.selectedChatRoomsSize) {
      return;
    }

    if (index === 0) {
      if (this.SelectedChatRooms[1].Expanded) {
        this.expandedTabs--;
      }
      this.SelectedChatRooms.splice(1, 1);
    } else {
      if (this.SelectedChatRooms[0].Expanded) {
        this.expandedTabs--;
      }
      this.SelectedChatRooms.splice(0, 1);
    }

    this.ChatRoomExpanded.emit();
  }

  unselectChatRoom(index: number) {
    if (this.SelectedChatRooms[index].Expanded) {
      this.expandedTabs--;
      this.setSelectedChatRoomsSize();
    }

    this.SelectedChatRooms.splice(index, 1);
  }

}
