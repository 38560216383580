import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import {
  CandidateParameters,
  ClientTalentPoolFilterOrderBy,
  JobType,
  MemberAvailabilityCondition,
  MemberType,
  OfferInvite,
  Pagination,
  SendOfferWithVacancy,
  UserInvite,
  Vacancy,
  VacancyDay,
  CandidateLiveLocation,
  JobsOpenPoolResult,
  TalentPoolFilterType,
  FillRequestReason,
  RoleType
} from '@interfaces';
import { GenericTag, Tag, TagTree } from '@models/tag.model';
import { AvailabilityDay } from '@models/availability-day.model';
import { Candidate, MatchResponse } from '@models/candidate.model';
import { map } from 'rxjs/operators';
import { JobPoolResult, JobsFilters, JobsOrderBy } from '../interfaces';
import * as dayjs from 'dayjs';
import { RoleGroupSet, TalentPoolResult } from '@app/models/talent-pool.model';
import { Dayjs } from 'dayjs';
import { BookSupplyTypeKey, JobTypeTabKey, RoleTypeTabKey } from './auth.service';
import { BookSupplyType } from '@app/modules/addjobs/pages/home/home.component';

@Injectable()
export class JobService {
  userInfo: any;
  jobId: any;
  jobDates: any[] = [];
  jobTags: any = [];
  jobRole: any;
  jobMessage: string;
  jobTitle: string;
  jobCandidates: any = [];
  replyTime: number;
  jobType: JobType;
  inviteSource: string;
  usedMultiDateRange: boolean = false;
  selectedTerms: any[] = [];
  offerDayRate: any = null;
  invoiceRate: any = null;
  invoiceHalfDayRate: any = null;
  applicationEnableFor: any;
  applicationCloseDate: any;
  weekDays: any = [];

  clientSiteId: string = null;
  clientContactId: string = null;

  private _jobTypeTab: string;

  get jobTypeTab(): string {
    return this._jobTypeTab;
  }

  set jobTypeTab(type: string) {
    this._jobTypeTab = type;
    localStorage.setItem(JobTypeTabKey, this._jobTypeTab.toString());
  }

  private _roleTypeTab: RoleType;

  get roleTypeTab(): RoleType {
    return this._roleTypeTab;
  }

  set roleTypeTab(type: RoleType) {
    this._roleTypeTab = type;
    localStorage.setItem(RoleTypeTabKey, this._roleTypeTab.toString());
  }

  private _bookSupplyTab: BookSupplyType = BookSupplyType.AllCandidates;

  get bookSupplyTypeTab(): BookSupplyType {
    return this._bookSupplyTab;
  }

  set bookSupplyTypeTab(type: BookSupplyType) {
    this._bookSupplyTab = type;
    localStorage.setItem(BookSupplyTypeKey, this._bookSupplyTab.toString());
  }
  
  constructor(private http: HttpService) {
    this.loadUserDashboardTabs();
  }

  loadUserDashboardTabs() {
    const roleType = localStorage.getItem(RoleTypeTabKey);
    const bookSuppplyType = localStorage.getItem(BookSupplyTypeKey);
    this._jobTypeTab = localStorage.getItem(JobTypeTabKey);

    if (roleType) {
      this._roleTypeTab = parseInt(roleType, 10);
    }

    if (bookSuppplyType) {
      this._bookSupplyTab = parseInt(bookSuppplyType, 10);
    }
  }

  getVacancyBestmatches(vacancyId: string): Observable<any> {
    let url: string = `/clients/vacancies/${vacancyId}/bestmatches`;
    return this.http.get(url);
  }

  getRolesByRole(roleId: string): Observable<any> {
    let url: string = `/clients/vacancies/roles/${roleId}/tags`;
    return this.http.get(url).pipe(
      map(data => {
        const res = TagTree.create();
        res.setItems(data);
        return res;
      })
    );
  }

  getVacancyOffered(vacancyId: string): Observable<any> {
    let url: string = `/clients/vacancies/offered/${vacancyId}`;
    return this.http.get(url);
  }

  postVacancy(vacancy: Vacancy = null): Observable<any> {
    if (vacancy == null) {
      vacancy = this.createVacancy(this.jobType);
    }

    let url: string = `/clients/vacancies`;
    return this.http.post(url, vacancy);
  }

  putVacancy(vacancyId: string, vacancy: Vacancy): Observable<any> {
    let url: string = `/clients/vacancies/${vacancyId}`;
    return this.http.put(url, vacancy);
  }

  getVacancy(vacancyId: string): Observable<any> {
    let url: string = `/clients/vacancies/${vacancyId}`;
    return this.http.get(url);
  }

  postVacancyBestmatches(vacancy: Vacancy): Observable<any> {
    if (vacancy == null) {
      vacancy = this.createVacancy(this.jobType);
    }

    let url: string = `/clients/vacancies/bestmatches`;
    return this.http.post(url, vacancy);
  }

  sendOffer(jobType): Observable<any> {
    const sendOfferWithVacancy = <SendOfferWithVacancy>{
      Vacancy: this.createVacancy(jobType),
      JobOffer: this.createJobOffer()
    };

    let url: string = `/clients/vacancies/invite`;
    return this.http.post(url, sendOfferWithVacancy);
  }

  createVacancyWithoutInvites(jobType, fillRequestReason: FillRequestReason): Observable<any> {
    const sendOfferWithVacancy = <SendOfferWithVacancy>{
      Vacancy: this.createVacancy(jobType, fillRequestReason),
      JobOffer: this.createJobOffer()
    };

    let url: string = `/clients/vacancies/createunfilled`;
    return this.http.post(url, sendOfferWithVacancy);
  }

  sendInvite(vacancyId: string): Observable<any> {
    let offerInvite = this.createJobOffer();

    let url: string = `/clients/vacancies/${vacancyId}/invite`;
    return this.http.post(url, offerInvite);
  }

  resendOffer(vacancyId: string): Observable<any> {
    let offerInvite = this.createJobOffer();

    let url: string = `/clients/vacancies/${vacancyId}/invite`;
    return this.http.post(url, offerInvite);
  }

  getVacancies(): Observable<any> {
    let url: string = `/clients/vacancies`;
    return this.http.get(url);
  }

  getVacanciesArchived(pageNr: number, pageSize: number): Observable<any> {
    let url: string = `/clients/vacancies/archived/${pageNr}/${pageSize}`;
    return this.http.get(url);
  }

  deleteVacancy(vacancyId: string): Observable<any> {
    let url: string = `/clients/vacancies/${vacancyId}/delete`;
    return this.http.delete(url);
  }

  getRateCard(roleId): Observable<any> {
    let url: string = `/clients/vacancies/${roleId}/ratecard`;
    return this.http.get(url);
  }

  sendJobDetailsConsultant(vacancyId: string, isLongTermJob: boolean): Observable<any> {
    let url: string = `/clients/sendjobdetailstoconsultant/${vacancyId}/${isLongTermJob}`;
    return this.http.get(url);
  }

  sendJobDetailsToJobConsultant(vacancyId: string): Observable<any> {
    let url: string = `/clients/sendJobDetailsToJobConsultant/${vacancyId}`;
    return this.http.get(url);
  }

  sendDailySupplyJobOfferSentEmail(vacancyId: string): Observable<any> {
    let url: string = `/clients/senddailysupplyjoboffersentemail/${vacancyId}`;
    return this.http.get(url);
  }

  inviteToInterview(vacancyId: string, candidateId: string): Observable<any> {
    let url: string = `/clients/longtermjobinvitetointerview/${vacancyId}/${candidateId}`;
    return this.http.get(url);
  }

  rejectCandidate(vacancyId: string, candidateId: string): Observable<any> {
    let url: string = `/clients/longtermjobrejectcandidate/${vacancyId}/${candidateId}`;
    return this.http.get(url);
  }

  getCandidateCV(candidateId: string): Observable<any> {
    let url: string = `/clients/getCandidateCV/${candidateId}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(res => {
        return [{
          type: res.type,
          data: res.slice(0, res.size)
        }];
      })
    );
  }

  updateJobDescription(jobDescriptionModel: any): Observable<any> {
    let url: string = `/clients/updateJobDescription`;
    return this.http.post(url, jobDescriptionModel);
  }

  getCandidateAsset(id: any): Observable<any> {
    let url: string = `/clients/getCandidateAsset/${id}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(res => {
        return [{
          type: res.type,
          data: res.slice(0, res.size)
        }];
      })
    );
  }

  /**
   * Add member to Talent pool
   *
   * @param {String} memberId
   */
  addCandidateToTalentPool(candidateId: any): Observable<any> {
    let url: string = `/clienttalentpool/add?candidateId=${candidateId}`;
    return this.http.get(url);
  }

  /**
   * Add member to approved talents
   *
   * @param {String} memberId
   */
  addCandidateToExploreTalent(candidateId: any): Observable<any> {
    let url: string = `/clienttalentpool/remove?candidateId=${candidateId}`;
    return this.http.get(url);
  }

  /**
   * Remove member from Talent pool
   *
   * @param {String} memberId
   */
  removeCandidateFromTalentPool(candidateId: any): Observable<any> {
    let url: string = `/clienttalentpool/remove?candidateId=${candidateId}`;
    return this.http.get(url);
  }

  resetJob() {
    this.jobId = null;
    this.jobDates = [];
    this.jobTags = [];
    this.jobRole = null;
    this.jobMessage = null;
    this.jobTitle = null;
    this.jobCandidates = [];
    this.replyTime = 0;
    this.offerDayRate = null;
    this.applicationEnableFor = 1;
    this.applicationCloseDate = null;
    this.weekDays = [];
    this.clientSiteId = null;
    this.clientContactId = null;
  }

  createVacancy(jobType, fillRequestReason: FillRequestReason = null): Vacancy {
    const vacancy = <Vacancy>{
      JobTitle: this.jobTitle,
      JobDescription: this.jobMessage,
      JobType: jobType,
      BookerName: this.userInfo.FirstName + ' ' + this.userInfo.LastName,
      FillRequestReason: fillRequestReason,
      UsedDateRange: this.usedMultiDateRange
    };

    if (jobType === JobType.LongTerm) {
      vacancy.ApplicationEnableFor = this.applicationEnableFor;

      const currentDate = dayjs.utc();
      vacancy.ReplyTime = Math.round(dayjs.duration(this.applicationCloseDate.diff(currentDate)).asSeconds());
      vacancy.ConsultantId = this.userInfo.AccountManager.Id;
    }
    else {
      if (this.userInfo.TalentConsultant) {
        vacancy.ConsultantId = this.userInfo.TalentConsultant.Id;
      }
      else {
        vacancy.ConsultantId = this.userInfo.AccountManager.Id;
      }
    }

    if (this.jobDates != null && this.jobDates.length > 0) {
      vacancy.StartDate = this.jobDates[0].date;
      vacancy.EndDate = this.jobDates[this.jobDates.length - 1].date;

      const vacancyDays: VacancyDay[] = [];
      for (const jobDate of this.jobDates) {
        vacancyDays.push(<VacancyDay>{
          Date: jobDate.date,
          BookingTimeType: jobDate.dayType,
          StartTime: jobDate.date.format('HH:mm'),
          InvoiceDayRate: jobDate.InvoiceDayRate,
          PayDayRate: jobDate.PayDayRate
        });
      }
      console.log(vacancyDays, 'days vacancy', );
      
      vacancy.VacancyDays = vacancyDays;
    }

    if (this.userInfo != null) {
      const clientDetails = this.userInfo.ClientDetails != null ? this.userInfo.ClientDetails : null;
      if (this.clientSiteId) {
        vacancy.ClientContactId = this.clientContactId;
        vacancy.ClientSiteId = this.clientSiteId;
      } else if (clientDetails != null) {
        vacancy.ClientContactId =
          (clientDetails.DefaultClientSite.ClientContactId === null || clientDetails.DefaultClientSite.ClientContactId === "null") ?
            (clientDetails.ClientContacts != null &&
              clientDetails.ClientContacts.length > 0 ? clientDetails.ClientContacts[0].Id : null) :
            clientDetails.DefaultClientSite.ClientContactId;
        vacancy.ClientSiteId =
          clientDetails.ClientSites != null && clientDetails.ClientSites.length > 0 ? clientDetails.ClientSites[0].Id : null;
      }
      vacancy.ClientId = this.userInfo.ClientId;
    }

    vacancy.RoleId = this.jobRole != null ? this.jobRole.Id : null;

    if (this.jobTags && Array.isArray(this.jobTags) && this.jobTags[0] instanceof GenericTag) {
      const vacancyTags: Tag[] = [];
      for (const tag of this.jobTags) {
        vacancyTags.push(<Tag>{
          Id: tag.Id
        });
      }
      vacancy.VacancyTags = vacancyTags;
    } else if (this.jobTags != null && this.jobTags.length > 0) {
      const vacancyTags: Tag[] = [];
      for (const jobTag of this.jobTags) {
        for (const tag of jobTag.Tags) {
          vacancyTags.push(<Tag>{
            Id: tag.Id
          });
        }
      }
      vacancy.VacancyTags = vacancyTags;
    }

    return vacancy;
  }

  createJobOffer(): OfferInvite {
    const minutes = Math.floor(this.replyTime / 60);

    const jobOffer = <OfferInvite>{
      Days: Math.floor(minutes / 1440),
      Hours: Math.floor(minutes % 1440 / 60),
      Minutes: Math.floor(minutes % 60),
      InviteSource: this.inviteSource 
    };

    if (this.jobCandidates != null && this.jobCandidates.length > 0) {
      const candidates = [];
      for (const candidate of this.jobCandidates) {
        candidates.push(<UserInvite>{
          CandidateId: candidate.CandidateId,
          MatchedProfileId: candidate.PrimaryProfile.Id,
          DailyRate: candidate.DayPayRate ? candidate.DayPayRate : undefined,
          HalfDayRate: candidate.HalfDayPayRate ? candidate.HalfDayPayRate : undefined
        });
      }
      jobOffer.Users = candidates;
    }

    return jobOffer;
  }

  getJobRole(id: string) {
    const url = `/clients/recentjobroles/${id}`;
    return this.http.get(url);
  }

  getCandidates(data: CandidateParameters): Observable<TalentPoolResult> {
    const url = `/clienttalentpool/candidates`;
    return this.http.post(url, data).pipe(
      map(res => {
        // res.Candidates.forEach(candidate => {
        //   candidate = new Candidate().deserialize(candidate);
        // })

        for (let i=0; i < res.Candidates.length; i++) {
          res.Candidates[i] = new Candidate().deserialize(res.Candidates[i]);
        }

        return res;
      })
    );
  }

  allCandidates = (from: Dayjs, to: Dayjs, pagination: Pagination, orderBy?: number, roleGroup: number = null, selectedTags: string[] = []) => {
    const params: CandidateParameters = {
      FetchParameters: {
        Type: TalentPoolFilterType.PoolAndRecommended,
        ReturnAvailability: true,
        ReturnJourneyTime: true,
        OrderBy: orderBy ? orderBy : ClientTalentPoolFilterOrderBy.AvailableDaysDesc,
        PageNr: pagination.page,
        PageSize: pagination.page_size
      },
      FilterParameters: {
        Search: '',
        JobType: 2,
        AvailabilityFrom: from.format('MM/DD/YYYY'),
        AvailabilityTo: to.format('MM/DD/YYYY'),
        ReturnedMembers: MemberAvailabilityCondition.Everyone,
        MinJobs: 0,
        MaxJobs: 500,
        FavouriteOnly: false,
        RoleGroup: roleGroup,
        SelectedTags: selectedTags
      }
    };
    return this.getCandidates(params);
  };

  pinJob(job) {
    const url = `/clients/favourite`;
    const body = {
      EntityId: job.RoleId,
      EntityType: 1,
      Data: ''
    };
    job.Tags.forEach(tag => {
      body.Data += tag.Id + ',';
    });
    if (!body.Data) {
      body.Data = null;
    } else {
      body.Data = body.Data.slice(0, -1);
    }
    return this.http.post(url, body);
  }

  unpinJob(id: string) {
    const url = `/clients/favourite/${id}`;
    return this.http.delete(url);
  }

  getMatch(
    days: AvailabilityDay[],
    jobId: string,
    jobTags: any[] = [],
    clientSiteId: string = '',
    memberType: MemberType = MemberType.Everyone,
    memberAvailability: MemberAvailabilityCondition = MemberAvailabilityCondition.Everyone,
    sort: number = 5
  ): Observable<MatchResponse> {
    const data = {
      JobRoleId: jobId,
      JobType: 2,
      JobTags: jobTags,
      Availability: [],
      ClientSiteId: clientSiteId,
      ClientSiteLatitude: '',
      ClientSiteLongitude: '',
      ReturnedMemberType: memberType,
      ReturnedMembersAvailability: memberAvailability,
      Sort: sort,
      SortDirection: 'desc',
      PageNo: -1,
      PageSize: -1
    };
    days.forEach(day => {
      data.Availability.push(
        day.serialize()
      );
    });
    const url = `/clients/match`;

    return this.http.post(url, data).pipe(
      map((matchResponse: MatchResponse) => {
        matchResponse.MatchedCandidates = matchResponse.MatchedCandidates.map(c => new Candidate().deserialize(c));
        return matchResponse;
      })
    );
  }

  getClientJobsLiveLocations(): Observable<CandidateLiveLocation[]> {
    const url: string = '/clients/jobs/livelocations';
    return this.http.get(url);
  }

  getClientActiveJobs(): Observable<JobPoolResult> {
    const url: string = "/clients/jobs/active";
    const prams: JobsFilters = {
      PageSize: 1000,
      PageNumber: 0,
      OrderBy: JobsOrderBy.StartDate
    };
    return this.http.post(url, prams);
  }

  getClientOpenJobs(): Observable<JobsOpenPoolResult> {
    const url: string = "/clients/jobs/open";
    const prams: JobsFilters = {
      PageSize: 1000,
      PageNumber: 0,
      OrderBy: JobsOrderBy.StartDate
    };
    return this.http.post(url, prams);
  }

}
