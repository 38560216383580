<div class="add-job" [attr.data-au]="'aj-page'">
  <div class="container pt-3">
    <div class="row">
      <div class="col" [attr.data-au]="'aj-header'">
        <h3 class="title" [attr.data-au]="'aj-header-title'">Book {{ localizedLabel.SupplyUC | localizeText }}</h3>
        <h5 class="subtitle" [attr.data-au]="'aj-header-subtitle'">Send job offers to one or multiple {{ localizedLabel.CandidatesLC | localizeText }}</h5>
      </div>
      <div class="col d-flex justify-content-end long-term-container" *ngIf="device.type === device.types.desktop">
        <div class="row mx-0 long-term-role-box" [attr.data-au]="'aj-lt-box'">
          <div class="col p-0 long-term-role-q" [attr.data-au]="'aj-lt-btn-label'">Need help filling a long term {{ localizedLabel.SupplyLC | localizeText }} role?</div>
          <div class="col p-0 long-term-role-btn">
            <button (click)="navigateToLongTerm()" class="btn btn-flat top-button-pl" [attr.data-au]="'aj-lt-btn'" (click)="logPostLongTermRole()">
              Send us the job
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    <app-tabs device [selectedTabIndex]="selectedTab"
      [onClickTab]="setBookSupplyType.bind(this)"
      [showSelectedTabTitle]="true"
      [showTabColorDivider]="true"
      tabsHeaderClass="container"
      tabsBodyClass="container"
      [attr.data-au]="'aj-tabs'">
      <ng-template #tabTitle1>
        <i class="m-icon va-m ro tab-icon" [attr.data-au]="'tab-icon'">people</i>
        <span class="tab-text" [attr.data-au]="'baj-tab-title'">{{candidateCount !== null ? (device.type === device.types.desktop ? candidateCount + ' ' + (localizedLabel.CandidatesLC | localizeText) + ' available' : ' Available ' + '(' + candidateCount + ')' ) : 'Build a job'}}</span>
      </ng-template>

      <ng-template #tabTitle2>
        <i class="m-icon va-m ro tab-icon" [attr.data-au]="'tab-icon'">star_border</i>
        <span class="tab-text" [attr.data-au]="'tp-tab-title'">{{talentPoolCount !== null ? 'Talent Pool (' + talentPoolCount + ')' : 'Talent Pool'}}</span>
      </ng-template>

      <ng-template #tabTitle3>
        <span class="bank-staff-icon tab-icon">{{ localizedLabel.BankStaffIcon | localizeText }}</span>
        <span class="tab-text" [attr.data-au]="'bs-tab-title'">{{bankStaffCount !== null ? (localizedLabel.BankStaffTitle | localizeText) + ' (' + bankStaffCount + ')' : (localizedLabel.BankStaffTitle | localizeText)}}</span>
      </ng-template>

      <app-tab [tabIndex]="BookSupplyTab.AllCandidates" [active]="selectedTab === BookSupplyTab.AllCandidates" [attr.data-au]="'baj-tab'"
        [tabTitle]="tabTitle1">

        <app-quick-book-job
          [attr.data-au]="'baj-component'"
          [tabActive]="selectedTab === BookSupplyTab.AllCandidates" 
          [refresh]="refreshAllCandidates" 
          [increaseTalentPoolCount]="increaseTalentPoolCount.bind(this)" 
          [changeCandidateCount]="changeCandidateCount.bind(this)"
          [bookingTerms]="bookingTerms" tabContent>
        </app-quick-book-job>
      </app-tab>
      <app-tab [tabIndex]="BookSupplyTab.TalentPool" [active]="selectedTab === BookSupplyTab.TalentPool" [attr.data-au]="'tp-tab'"
        [tabTitle]="tabTitle2">

        <app-book-talent
          [attr.data-au]="'tp-component'"
          [tabActive]="selectedTab === BookSupplyTab.TalentPool" 
          [supplyType]="BookSupplyTab.TalentPool"
          [refresh]="refreshTalentPool" 
          [refreshAllCandidatesList]="refreshAllCandidatesList.bind(this)" 
          [changeTalentPoolCount]="changeCandidateCount.bind(this)"
          [skipUrlFragmentHandling]="skipUrlFragmentHandling"
          [bookingTerms]="bookingTerms" tabContent>
        </app-book-talent>
      </app-tab>
      <app-tab [tabIndex]="BookSupplyTab.BankStaff" [active]="selectedTab === BookSupplyTab.BankStaff" [attr.data-au]="'tp-tab'"
      [tabTitle]="tabTitle3">

        <app-book-talent
          [attr.data-au]="'bs-component'"
          [tabActive]="selectedTab === BookSupplyTab.BankStaff" 
          [supplyType]="BookSupplyTab.BankStaff"
          [refresh]="refreshTalentPool" 
          [refreshAllCandidatesList]="refreshAllCandidatesList.bind(this)" 
          [changeTalentPoolCount]="changeCandidateCount.bind(this)"
          [skipUrlFragmentHandling]="skipUrlFragmentHandling"
          [bookingTerms]="bookingTerms" tabContent>
        </app-book-talent>
      </app-tab>
    </app-tabs>
</div>
