import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    this.noHeaderAndFooter();
  }

  noHeaderFooter: boolean;

  noHeaderAndFooter() {
    if (location.pathname.indexOf('/signup') > -1
      || location.pathname.indexOf('/confirmemail') > -1
      || location.pathname.indexOf('/embeddedsignin') > -1
      || location.pathname.indexOf('/embeddedsigninpanel') > -1
      || location.pathname.indexOf('/forgotten-password') > -1
      || location.pathname.indexOf('/signedin') > -1
      || location.pathname.indexOf('/signout') > -1
      || location.pathname.indexOf('/login') > -1
    ) {
      this.noHeaderFooter = true;
    } else {
      this.noHeaderFooter = false;
    }
  }

}
