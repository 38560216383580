import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-reference-questionnaire',
    templateUrl: './reference-questionnaire.component.html',
    styleUrls: ['./reference-questionnaire.component.scss']
})
export class ReferenceQuestionnaireComponent implements OnInit {
    @Input() reference: any;
    @Input() disabled: boolean = false;

    @Input() submitLoader: boolean = false;
    @Input() onUpdateReference: Function;
    @Input() submissionError: boolean = false;

    isNotValid: boolean = false;
    constructor() { }

    ngOnInit() {
    }

    updateReference() {
        this.validateControls();

        if (this.isNotValid) {
            return;
        }

        this.onUpdateReference();
    }

    validateControls() {
        this.isNotValid = false;
        if (this.reference.ScorePupilRelationship == null) {
            this.isNotValid = true;
        }
        if (this.reference.ScoreColleagueRelationship == null) {
            this.isNotValid = true;
        }
        if (this.reference.ScoreAttendance == null) {
            this.isNotValid = true;
        }
        if (this.reference.ScoreKnowledge == null) {
            this.isNotValid = true;
        }
        if (this.reference.ScorePlanning == null) {
            this.isNotValid = true;
        }
        if (this.reference.ScoreComms == null) {
            this.isNotValid = true;
        }
        if (this.reference.ReEmploy == null) {
            this.isNotValid = true;
        }
        if (this.reference.Safeguard == null) {
            this.isNotValid = true;
        }
        if (this.reference.Safeguard === 1 && (this.reference.SafeguardNotes == null || this.reference.SafeguardNotes == '')) {
            this.isNotValid = true;
        }
    }

    showConfirmationStatement(): boolean {
        if (!this.reference) {
          return false;
        }
    
        return this.disabled && this.reference.Status >= 3;
      }
}
