import { AbstractControl, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidatorSmall = function (control: AbstractControl): ValidationErrors | null {
    let value: string = control.value || '';
    if (!value) {
        return null
    }
    let numberCharacters = /[0-9]+/g;
    if (value.length < 6 || numberCharacters.test(value) === false ) {
        return {
            passwordStrength: 'Please ensure your password is: 6 characters minimum and includes at least one number.'
        }
    }
}