import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Timesheet, TimesheetItem } from '../interfaces';

@Injectable()
export class TimesheetService {

  public selectedTimesheets: TimesheetItem[] = [];
  public selectedTotalFullDays: number = 0;
  public selectedTotalHalfDays: number = 0;

  constructor(private http: HttpService) {
  }

  getTimesheets(year: number, month: number): Observable<Timesheet[]> {
    let url: string = `/clients/alltimesheets?year=${year}&month=${month}`;
    return this.http.get(url);
  }

  getTimeSheetDetailsAllTime(): Observable<any[]> {
    let url: string = `/clients/timesheets/details/alltime`;
    return this.http.get(url);
  }

  getTimeSheetDetails(timeSheetId: string): Observable<Timesheet> {
    let url: string = `/clients/timesheets/details/${timeSheetId}`;
    return this.http.get(url);
  }

  getTimeSheetDetailsForNotReviewed(): Observable<TimesheetItem[]> {
    let url: string = `/clients/notreviewed`;
    return this.http.get(url);
  }

  getTimeSheetDetailsForNotReviewedCount(): Observable<number> {
    let url: string = `/clients/notreviewed/count`;
    return this.http.get(url);
  }

  signOff(timesheetItems: TimesheetItem[]): Observable<any> {
    let url: string = `/clients/timesheets/signoff`;
    return this.http.post(url, timesheetItems);
  }

  addToSelection(timesheetItem: TimesheetItem) {
    this.selectedTimesheets = [...this.selectedTimesheets, timesheetItem];
    this.selectedTotalFullDays += timesheetItem.FullDaysCount;
    this.selectedTotalHalfDays += timesheetItem.HalfDaysCount;
  }

  removeFromSelection(timesheetItem: TimesheetItem) {
    let index = this.selectedTimesheets.findIndex(t => t.VacancyDays[0].Id === timesheetItem.VacancyDays[0].Id);
    this.selectedTimesheets.splice(index, 1);
    this.selectedTimesheets = [...this.selectedTimesheets];
    this.selectedTotalFullDays -= timesheetItem.FullDaysCount;
    this.selectedTotalHalfDays -= timesheetItem.HalfDaysCount;
  }

  resetSelection() {
    this.selectedTimesheets = [];
    this.selectedTotalFullDays = 0;
    this.selectedTotalHalfDays = 0;
  }
}
