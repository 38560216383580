import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/localize/init';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as dayjs from 'dayjs';
import 'dayjs/plugin/timezone'
import 'dayjs/plugin/isoWeek';
import * as UTC from 'dayjs/plugin/utc';
import * as CustomDateFormat from 'dayjs/plugin/customParseFormat';
import * as Duration from 'dayjs/plugin/duration';
import * as IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)
dayjs.extend(localizedFormat)
dayjs.extend(CustomDateFormat);
dayjs.extend(UTC);
dayjs.extend(Duration);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw-master.js');
  }
}).catch(err => console.log(err));
