import { Component, Input, OnInit } from '@angular/core';
import { NavListComponent } from '@components/nav-list/nav-list.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hub-nav-list',
  templateUrl: './hub-nav-list.component.html',
  styleUrls: [
    '../nav-list.component.scss',
    './hub-nav-list.component.scss'
  ]
})
export class HubNavListComponent extends NavListComponent implements OnInit {
  @Input() hasContentCreationPermission = false;

  constructor(
    protected router: Router
  ) {
    super(router);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
