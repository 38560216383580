import { VacancyDay, JobType } from './vacancy';
import { Candidate } from './candidate';
import { Client } from './client';
import { Tag } from '@app/models';

export interface Placement {
  Id: number;
  Uid: string;
  JobType: JobType;
  JobRole: string;
  JobTitle: string;
  JobDescription: string;
  Client: Client;
  Reference: string;
  StartDate: Date;
  EndDate: Date;
  ConsultantEmail: string;
  Duration: string;
  VacancyDays: VacancyDay[];
  VacancyTags: Tag[];
  CheckInStatus: PlacementCheckInStatus;
  Candidate: Candidate;
  Rate: number;
  LastModified: Date;
}

export enum PlacementCheckInStatus {
  CheckedIn = 0,
  NotCheckedIn = 1,
  NotBookedToday = 2
}
