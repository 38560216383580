<div *ngIf="userInfo" class="{{'chatpopup' + (unreadMessages > 0 && !expanded ? ' new-messages' : '') + (!expanded ? ' collapsed' : '')}}">

  <div class='row no-gutters chatpopup-header' (click)="toogleChatPopup()" 
    [ngClass]="{ 'expanded': expanded}">
    <div class="col-auto profile-image">
      <div *ngIf="defaultCandidatePhotoUrl" class="square op-avatar"></div>
      <img *ngIf="!defaultCandidatePhotoUrl" src="{{userInfo.ProfileImageUrl}}"/>
    </div>
    <div class="col px-2 align-self-center title">
      <span>Live chat </span>
      <span *ngIf="unreadMessages > 0 && !expanded">({{unreadMessages}})</span>
    </div>
    <div class="col-auto px-2 align-self-center expand-icon">
      <span *ngIf="expanded" class="material-icons align-middle">expand_more</span>
      <span *ngIf="!expanded" class="material-icons align-middle">expand_less</span>
    </div>
  </div>

  <div *ngIf="expanded" class="chatpopup-body">
    <app-tabs [selectedTabIndex]="selectedTabIndex" [onClickTab]="setTab.bind(this)">
      <ng-template #tabTitle1>
        <span class="tab-text" [attr.data-au]="'tab-title'">{{ (localizedLabel.CandidateUC | localizeText) + (unreadCandidateMessages > 0 ? ' (' + unreadCandidateMessages + ')' : '')}}</span>
      </ng-template>

      <ng-template #tabTitle2>
        <span class="tab-text" [attr.data-au]="'tab-title'">{{'Opogo' + (unreadConsultantMessages > 0 ? ' (' + unreadConsultantMessages + ')' : '')}}</span>
      </ng-template>

      <app-tab [tabTitle]="tabTitle1">
        <app-chatrooms *ngIf='candidateChatRooms.length > 0' [chatRooms]='candidateChatRooms'></app-chatrooms>
        <div *ngIf='candidateChatRooms.length === 0' class="no-candidates pt-1 pb-5">Accepted {{ localizedLabel.CandidatesLC | localizeText }} will appear here</div>
      </app-tab>
      <app-tab [tabTitle]="tabTitle2">
        <div class="call-opogo pt-1 pb-3">Call us: {{consultant.PhoneNumber}}</div>
        <app-chatrooms [chatRooms]='consultantChatRooms'></app-chatrooms>
      </app-tab>
    </app-tabs>
  </div>

  <app-chat-messages [index]="0" *ngIf='getSelectedChatRoomsSize() >= 1'></app-chat-messages>
  <app-chat-messages [index]="1" *ngIf='getSelectedChatRoomsSize() >= 2'></app-chat-messages>
  <app-chat-messages [index]="2" *ngIf='getSelectedChatRoomsSize() === 3'></app-chat-messages>
</div>
