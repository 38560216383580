import { Injectable } from '@angular/core';

import { Dayjs } from 'dayjs';
import { Observable } from 'rxjs';
import { BookSupplyType } from '../modules/addjobs/pages/home/home.component';
import { HttpService } from './http.service';
import { TalentPoolResult } from '@app/models';

export interface SupplyCandidatesRequest {
  SupplyType: BookSupplyType;
  Filter: CandidatesRequestFilter;
}

export interface CandidatesRequestFilter {
  RoleGroup?: number;
  AvailabilityFrom: Dayjs | Date;
  AvailabilityTo: Dayjs | Date;
  SelectedTags: string[];
  OrderBy: number;
  Page: number;
  PageLength: number;
}

@Injectable()
export class SupplyService {

  constructor(private http: HttpService) {}

  getCandidates(request: SupplyCandidatesRequest): Observable<TalentPoolResult> {
    let url: string = '/clients/supply/candidates';

    return this.http.post(url, request);
  }
}
